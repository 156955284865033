<template>
  <LoadingContainer :is-loading="isLoadingReviews && !isLoadedReviews">
    <div class="card">
      <PaginatedListHeaderTemplate
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        title="Σχόλια"
      />
      <div class="card-content">
        <NewPagination
          :items="reviews"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoadedReviews"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="reviews.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Σχόλιο</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Stars"
                        attribute="stars"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τύπος"
                        attribute="reviewable_type"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Πελάτης"
                        attribute="profile_id"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Ημ/νια Δημιουργίας"
                        attribute="created_at"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Εμφανές"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <review
                    v-for="(item, index) in reviews"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    @changeItemActive="changeItemActive"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν σχόλια
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="reviews.length"
          :items="reviews"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaginatedList from '@/views/components/PaginatedList';
import PaginatedListHeaderTemplate from '@/views/components/PaginatedList/components/PaginatedListHeaderTemplate';
import Review from './components/Review';

export default {
  components: {
    Review,
    PaginatedListHeaderTemplate,
  },
  extends: PaginatedList,
  metaInfo: {
    title: 'Σχόλια',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Προβολή',
        title: 'active',
        type: 'boolean',
        value: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      reviews: 'reviews/list/getData',
      paginationParams: 'reviews/list/getPaginationParams',
      isLoadingReviews: 'reviews/list/getIsLoading',
      isLoadedReviews: 'reviews/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getReviews(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getReviews: 'reviews/list/getReviews',
      updateReviewActiveStatus: 'reviews/list/updateReviewActiveStatus',
    }),
    async navigate(params = {}) {
      try {
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async changeItemActive(selectedItem) {
      try {
        const isActive = !selectedItem.active;

        await this.updateReviewActiveStatus({
          uuid: selectedItem.uuid,
          data: {
            active: !!isActive,
          },
        });

        await this.navigate({
          timestamp: new Date().getTime(),
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Το σχόλιο δημοσιεύτηκε επιτυχώς!!'
            : 'Το σχόλιο δεν προβάλλεται πλέον',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
