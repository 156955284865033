<template>
  <LoadingContainer :is-loading="isLoadingQuestions && !isLoadedQuestions">
    <div class="card">
      <PaginatedListHeaderTemplate
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        title="Ρωτήστε τους Ειδικούς"
      >
        <template v-slot:options>
          <router-link :to="{ name: 'content.questions.add' }" class="button">
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </template>
      </PaginatedListHeaderTemplate>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="questions"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoadedQuestions"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="questions.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="content"
                        @sort="sort"
                      />
                    </th>
                    <th>Απάντηση</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Εμφανές"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <question
                    v-for="(item, index) in questions"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @toggleSelection="toggleSelection"
                    @changeItemActive="changeItemActive"
                    @askToDeleteItem="askToDeleteItem"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν ερωτήσεις
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="questions.length"
          :items="questions"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>

      <confirmation
        :model-name="selectedItem.content"
        :is-open="isOpen"
        description="Είστε βέβαιος για τη διαγραφή αυτής της ερώτησης;"
        @closeModal="closeModal"
      />
    </div>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import PaginatedListHeaderTemplate from '@/views/components/PaginatedList/components/PaginatedListHeaderTemplate';
import Question from './components/Question';

export default {
  name: 'QuestionsList',
  components: {
    Question,
    PaginatedListHeaderTemplate,
  },
  extends: PaginatedList,
  metaInfo: {
    title: 'Ρωτήστε τους Ειδικούς',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Προβολή',
        title: 'active',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Απάντηση',
        title: 'has_answer',
        type: 'boolean',
        value: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      questions: 'questions/list/getData',
      paginationParams: 'questions/list/getPaginationParams',
      isLoadingQuestions: 'questions/list/getIsLoading',
      isLoadedQuestions: 'questions/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getQuestions(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getQuestions: 'questions/list/getQuestions',
    }),
    async navigate(params = {}) {
      try {
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async changeItemActive(selectedItem) {
      try {
        const isActive = !selectedItem.active;

        await request.put(`/questions/${selectedItem.uuid}/active`, {
          active: !!isActive,
        });

        await this.navigate({
          timestamp: new Date().getTime(),
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Το ερώτημα δημοσιεύτηκε επιτυχώς!!'
            : 'Το ερώτημα δεν προβάλλεται πλέον',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/questions/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'H ερώτηση διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
