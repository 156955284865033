<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Συχνές Ερωτήσεις <span class="tag">Προσθήκη Ερώτησης</span>
        </div>
      </div>
      <div class="card-header-icon">
        <router-link :to="{ name: 'content.faqs.list' }" class="button">
          <span class="icon is-small"><i class="fa fa-list"/></span>
          <span>Λίστα</span>
        </router-link>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Ερώτηση *</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model.trim="newFaq.title"
              class="textarea"
              type="text"
              name="title"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε ερώτηση
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Απάντηση *</label>
          <div class="control">
            <VueEditor
              v-validate="'required'"
              id="editor"
              v-model="newFaq.content"
              :editor-toolbar="toolbarOptions"
              data-vv-value-path="value"
              data-vv-name="content"
              @input="changeContent"
            />
            <p v-show="errors.has('content')" class="help is-danger">
              Εισάγετε απάντηση
            </p>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { VueEditor } from 'vue2-editor';
import AddItem from '@/views/components/AddItem';
import toolbarOptions from '@/constants/toolbarOptions';

export default {
  components: {
    VueEditor,
  },
  extends: AddItem,
  data() {
    return {
      newFaq: {
        title: '',
        content: '',
      },
      isSaving: false,
      toolbarOptions,
    };
  },
  methods: {
    ...mapActions({
      addFaq: 'faqs/add/addFaq',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addFaq(this.newFaq);
        this.isSaving = false;

        this.$router
          .push({ name: 'content.faqs.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ερώτηση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newFaq = {
        title: '',
        content: '',
      };

      this.$validator.reset();
    },
    changeContent(content) {
      this.newFaq = {
        ...this.newFaq,
        content: content === '<p><br></p>' ? '' : content,
      };
    },
  },
};
</script>
