<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Χρήστες
        </div>
      </div>
      <div class="card-header-icon">
        <router-link :to="{ name: 'users.list' }" class="button">
          <span class="icon is-small"><i class="fa fa-list"/></span
          ><span>Λίστα</span>
        </router-link>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Όνομα *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="newUser.name"
              class="input"
              type="text"
              name="name"
            />
            <p v-show="errors.has('name')" class="help is-danger">
              Εισάγετε όνομα
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Email *</label>
          <div class="control">
            <input
              v-validate="'required|email'"
              v-model.trim="newUser.email"
              class="input"
              type="email"
              name="email"
            />
            <p v-show="errors.has('email:required')" class="help is-danger">
              Εισάγετε email
            </p>
            <p v-show="errors.has('email:email')" class="help is-danger">
              Εισάγετε έγκυρο email
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Δικαιώματα *</label>
          <div class="control">
            <p class="select is-fullwidth">
              <select v-model.trim="newUser.authority" name="authority">
                <option value="1">Super Admin</option>
                <option value="2">Admin</option>
                <option value="3">Editor</option>
              </select>
            </p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import request from '@/utils/request';
import FormSubmit from '@/views/components/FormSubmit';

export default {
  components: {
    FormSubmit,
  },
  data() {
    return {
      newUser: {
        name: '',
        email: '',
        authority: 3,
      },
      isSaving: false,
    };
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/users', this.newUser);
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router
          .push({ name: 'users.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο χρήστης αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newUser = {
        name: '',
        email: '',
        authority: 3,
      };

      this.$validator.reset();
    },
  },
};
</script>
