<template>
  <div v-if="isLoading" class="loading-container has-text-centered">
    <img src="@/assets/logo_green.png" alt="Carespot" />
    <vue-loading
      :size="{ width: '60px', height: '60px' }"
      type="spin"
      color="#22A684"
    />
  </div>
  <layout v-else>
    <router-view />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueLoading from 'vue-loading-template';
import Layout from '@/views/components/Layout';

export default {
  components: {
    VueLoading,
    Layout,
  },
  data: () => ({
    timer: null,
  }),
  computed: {
    ...mapGetters({
      isLoading: 'getIsLoadingSettings',
    }),
  },
  async created() {
    try {
      await this.getSettings();
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },

  mounted() {
    // Run immediately and then ask for erp status every 30secs
    this.getErpStatus();
    this.timer = setInterval(() => {
      this.getErpStatus();
    }, 10000);
  },

  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions(['getSettings', 'getErpStatus']),
  },
};
</script>

<style scoped lang="scss">
.loading-container {
  margin: 200px auto 0;
}

img {
  margin-bottom: 60px;
}
</style>
