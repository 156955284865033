<template>
  <LoadingContainer :is-loading="isLoading">
    <form novalidate @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Προσθήκη Set
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link
                  :to="{ name: 'marketing.sets.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list-ul"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Ονομασία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="general.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε ονομασία
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Περιγραφή *</label>
                <div class="control">
                  <textarea
                    v-validate="'required'"
                    v-model.trim="general.description"
                    class="textarea"
                    name="description"
                  />
                  <p v-show="errors.has('description')" class="help is-danger">
                    Εισάγετε περιγραφή
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Περιεχόμενο *</label>
                <div class="control">
                  <VueEditor
                    v-validate="'required'"
                    id="editor-1"
                    v-model="general.content"
                    :editor-toolbar="toolbarOptions"
                    data-vv-value-path="value"
                    data-vv-name="content"
                    @input="changeContent('content', $event)"
                  />
                </div>
                <p v-show="errors.has('content')" class="help is-danger">
                  Εισάγετε περιεχόμενο
                </p>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Συστατικά</label>
                <div class="control">
                  <VueEditor
                    id="editor-2"
                    :editor-toolbar="toolbarOptions"
                    v-model="general.ingredients"
                    @input="changeContent('ingredients', $event)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">Διαθεσιμότητα *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-model.trim.number="general.availability_id"
                      name="availability_id"
                    >
                      <option
                        v-for="item in availabilities"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Βάρος *</label>
                <div class="control">
                  <input
                    v-validate="'required|excluded:0'"
                    v-model.trim.number="general.weight"
                    class="input"
                    type="number"
                    name="weight"
                    min="0"
                    step="0.01"
                  />
                  <p v-show="errors.has('weight')" class="help is-danger">
                    Εισάγετε βάρος
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard :is-open="true" title="Set Items *">
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των set items θα
              μεταβάλλει το συνολικό <strong>Βάρος</strong> αυτόματα
            </li>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των set items θα
              μεταβάλλει την <strong>Π.Λ. Τιμή Πώλησης</strong> και την
              <strong>Τελική Τιμή</strong> αυτόματα
            </li>
          </ul>
        </template>
        <div class="card-content">
          <table
            v-if="general.subsets.length"
            class="table is-bordered is-striped is-fullwidth"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Φώτο</th>
                <th>Barcodes</th>
                <th>Common ID</th>
                <th>Τίτλος</th>
                <th>Π.Λ. Τιμή (&euro;)</th>
                <th>Έκπτωση (&#x00025;)</th>
                <th>Τελική Τιμή (&euro;)</th>
                <th>Προβολή</th>
                <th>Ποσότητα</th>
                <th>Βάρος<br />(ανά προιόν)</th>
                <th>Ενέργειες</th>
              </tr>
            </thead>
            <tbody>
              <product
                v-for="(item, index) in general.subsets"
                :item="item"
                :index="index"
                :key="item.id"
                @onDelete="onDeleteSubset"
                @onChangeCount="onChangeCount"
              />
            </tbody>
          </table>

          <products-search :set="general" @selectProduct="onSelectSubset" />
          <div v-show="errors.has('subsets')" class="help is-danger">
            Επιλέξτε τουλάχιστον 1 προιόν
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Τιμολογιακή Πολιτική">
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των set items θα
              μεταβάλλει την <strong>Π.Λ. Τιμή Πώλησης</strong> και την
              <strong>Τελική Τιμή</strong> αυτόματα
            </li>
            <li>
              <i class="fa fa-check" /> Στα sets η
              <strong>Τελική Τιμή</strong> δεν μπορεί να μεταβληθεί άμεσα απο το
              χρήστη. Προτιμήστε την αλλαγή του
              <strong>Ποσοστού Έκπτωσης</strong>
            </li>
          </ul>
        </template>
        <div class="card-content">
          <EditPrice :product="general" type="set" @updatePrice="updatePrice">
            <div class="column is-half">
              <div class="field">
                <label class="label">ΦΠΑ *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model.trim.number="general.tax_id" name="tax_id">
                      <option
                        v-for="item in taxes"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.percentage }} %
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </EditPrice>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Κατηγορίες">
        <div class="card-content">
          <EditCategories
            :categories="general.categories"
            @updateCategories="updateCategories"
          />
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Συμπτώματα & Tags">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <label class="label">Συμπτώματα</label>
              <symptoms-search
                :symptoms="general.symptoms"
                @selectSymptom="selectSymptom"
                @removeSymptom="removeSymptom"
              />
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Tags</label>
                <div class="control">
                  <multiselect
                    v-model="general.tags"
                    :options="tags"
                    :multiple="true"
                    name="tags"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε tags"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </LoadingContainer>
</template>

<script>
import { isEqual, round } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import { VueEditor } from 'vue2-editor';
import AddItem from '@/views/components/AddItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import EditPrice from '@/views/components/EditPrice';
import toolbarOptions from '@/constants/toolbarOptions';
import EditCategories from '../components/EditCategories';
import SymptomsSearch from '../components/SymptomsSearch';
import ProductsSearch from '../components/ProductsSearch';
import Product from '../components/Product';

export default {
  components: {
    VueEditor,
    Multiselect,
    LoadingContainer,
    ToggleableCard,
    EditCategories,
    EditPrice,
    SymptomsSearch,
    ProductsSearch,
    Product,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        title: '',
        barcode: '',
        description: '',
        content: '',
        ingredients: '',
        buy_price: 0,
        sell_price: 0,
        discount: 0,
        final_price: 0,
        weight: 0,
        tax_id: null,
        availability_id: 1,
        tags: [],
        brand_id: null,
        brand: null,
        collection_id: null,
        collection: null,
        symptoms: [],
        categories: [],
        subsets: [],
      },
      isSaving: false,
      toolbarOptions,
    };
  },
  computed: {
    ...mapGetters({
      availabilities: 'getAvailabilities',
      taxes: 'taxes/list/getTaxes',
      tags: 'tags/all/getTags',
      categoriesReverseTree: 'categories/list/getCategoriesReverseTree',
      isLoading: 'categories/list/getIsLoading',
    }),
  },
  watch: {
    taxes(newVal) {
      this.general.tax_id = newVal.find(tax => tax.default === 1).id;
    },
    'general.subsets': {
      /* eslint-disable camelcase */
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          const totalWeight = round(
            newVal.reduce(
              (acc, { weight, pivot }) => acc + weight * pivot.count,
              0,
            ),
            2,
          );
          const totalBuyPrice = round(
            newVal.reduce(
              (acc, { buy_price, pivot }) => acc + buy_price * pivot.count,
              0,
            ),
            2,
          );
          const totalSellPrice = round(
            newVal.reduce(
              (acc, { sell_price, pivot }) => acc + sell_price * pivot.count,
              0,
            ),
            2,
          );
          const totalFinalPrice = round(
            totalSellPrice - (this.general.discount * totalSellPrice) / 100,
            2,
          );

          this.general = {
            ...this.general,
            weight: totalWeight,
            buy_price: totalBuyPrice,
            sell_price: totalSellPrice,
            final_price: totalFinalPrice,
          };
        }
      },
      deep: true,
    },
  },
  async created() {
    try {
      await Promise.all([
        this.getTags(),
        this.getTaxes(),
        this.getCategories(),
      ]);
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getTags: 'tags/all/getTags',
      getTaxes: 'taxes/list/getTaxes',
      getCategories: 'categories/list/getCategories',
      addSet: 'sets/add/addSet',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addSet({
          ...this.general,
          tags: this.general.tags.map(({ id }) => id),
          symptoms: this.general.symptoms.map(({ id }) => id),
          categories: this.general.categories.map(
            ({ uuid }) => this.categoriesReverseTree[uuid].id,
          ),
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το set αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = {
        title: '',
        barcode: '',
        description: '',
        content: '',
        ingredients: '',
        buy_price: 0,
        sell_price: 0,
        discount: 0,
        final_price: 0,
        weight: 0,
        tax_id: this.defaultTax,
        availability_id: 1,
        tags: [],
        brand_id: null,
        brand: null,
        collection_id: null,
        collection: null,
        symptoms: [],
        categories: [],
        subsets: [],
      };

      this.$validator.reset();
    },
    changeContent(el, content) {
      this.general[el] = content === '<p><br></p>' ? '' : content;
    },
    selectSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: [...this.general.symptoms, symptom],
      };
    },
    removeSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: this.general.symptoms.filter(({ id }) => id !== symptom.id),
      };
    },
    updateCategories({ categories }) {
      this.general = {
        ...this.general,
        categories,
      };
    },
    updatePrice(price) {
      this.general = {
        ...this.general,
        ...price,
      };
    },
    onDeleteSubset(subset) {
      this.general = {
        ...this.general,
        subsets: this.general.subsets.filter(({ id }) => id !== subset.id),
      };
    },
    onSelectSubset(subset) {
      this.general = {
        ...this.general,
        subsets: [
          ...this.general.subsets,
          {
            ...subset,
            pivot: {
              count: 1,
            },
          },
        ],
      };
    },
    onChangeCount(id, count) {
      this.general = {
        ...this.general,
        subsets: this.general.subsets.map(subset => {
          if (subset.id === id) {
            return {
              ...subset,
              pivot: {
                count,
              },
            };
          }

          return subset;
        }),
      };
    },
  },
};
</script>

<style scoped>
.multiselect {
  margin: 0 auto !important;
}
</style>
