<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form class="has-sticky-submit" novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Επεξεργασία Συμπτώματος
                  <span class="tag">
                    <strong>{{ model.title }}</strong>
                  </span>
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field">
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.symptoms.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <EditGeneralDetails
                :general="general"
                :can-activate="true"
                @updateGeneral="updateGeneral"
              />
            </div>
          </div>

          <edit-photos
            :model="model"
            :photos="photos"
            :is-loading="isLoadingPhotos"
            @updatePhotos="updatePhotos"
          />

          <ToggleableCard :is-open="true" title="Ανακατεύθυνση (301)">
            <div class="card-content">
              <EditRedirectionDetails
                :redirection="redirection"
                @updateRedirection="updateRedirection"
              />
            </div>
          </ToggleableCard>

          <ToggleableCard title="Meta">
            <div class="card-content">
              <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
            </div>
          </ToggleableCard>

          <ToggleableCard title="Social Meta">
            <div class="card-content">
              <EditSocialMetaDetails
                :social-meta="socialMeta"
                @updateSocialMeta="updateSocialMeta"
              />
            </div>
          </ToggleableCard>

          <StickyFormFooter>
            <template v-slot:left>
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </template>
            <template v-slot:right>
              <a
                v-tooltip="'Προβολή στο e-shop'"
                :href="model.path"
                target="_blank"
              >
                {{ model.title }} <i class="fa fa-arrow-right" />
              </a>
            </template>
          </StickyFormFooter>
        </form>
      </div>
      <div class="column">
        <ExistingSymptoms :items="items" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _clone from 'lodash/clone';
// import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import EditRedirectionDetails from '@/views/components/EditRedirectionDetails';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';
import EditPhotos from '@/views/components/EditPhotos';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import ExistingSymptoms from '../../components/ExistingSymptoms';

export default {
  components: {
    EditRedirectionDetails,
    EditMetaDetails,
    EditSocialMetaDetails,
    EditGeneralDetails,
    EditPhotos,
    ToggleableCard,
    LoadingContainer,
    StickyFormFooter,
    ExistingSymptoms,
  },
  extends: AddItem,
  data() {
    return {
      redirection: {},
      meta: {},
      socialMeta: {},
      general: {},
      photos: [],
      isLoadingPhotos: false,
    };
  },
  computed: {
    ...mapGetters({
      model: 'symptoms/edit/getSymptom',
      items: 'symptoms/all/getSymptoms',
      isLoadingModel: 'symptoms/edit/getIsLoading',
      isLoadingItems: 'symptoms/all/getIsLoading',
    }),
  },
  watch: {
    $route: 'fetchData',
    model: {
      handler(newVal) {
        const { title, slug, description, content, active } = newVal;
        this.general = {
          title,
          slug,
          description,
          content,
          active,
        };

        this.redirection = _clone(
          newVal.redirection || {
            new: null,
            old: null,
          },
        );
        this.meta = _clone(newVal.meta);
        this.socialMeta = _clone(newVal.social_meta);
        this.photos = _clone(newVal.photos);
      },
      deep: true,
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getSymptom: 'symptoms/edit/getSymptom',
      getSymptomPhotos: 'symptoms/edit/getSymptomPhotos',
      updateSymptom: 'symptoms/edit/updateSymptom',
      getSymptoms: 'symptoms/all/getSymptoms',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getSymptom({ uuid: this.$route.params.uuid }),
          this.getSymptoms(),
        ]);
      } catch (err) {
        this.$router.push('/error').catch(e => console.log(e));
      }
    },
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.updateSymptom({
          uuid: this.model.uuid,
          symptom: {
            ...this.general,
            redirection: this.redirection,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το σύμπτωμα αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      const { title, slug, description, content, active } = this.model;
      this.general = {
        title,
        slug,
        description,
        content,
        active,
      };

      this.redirection = _clone(
        this.model.redirection || {
          new: null,
          old: null,
        },
      );
      this.meta = _clone(this.model.meta);
      this.socialMeta = _clone(this.model.social_meta);
      this.photos = _clone(this.model.photos);

      this.$children.forEach(child => child.$validator.reset());
    },
    updateRedirection(redirection) {
      this.redirection = redirection;
    },
    updateMeta(meta) {
      this.meta = meta;
    },
    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },
    updateGeneral(general) {
      this.general = general;
    },
    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;
        await this.getSymptomPhotos({ uuid: this.$route.params.uuid });
        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },
  },
};
</script>
