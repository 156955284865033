<template>
  <LoadingContainer :is-loading="isLoadingLogs && !isLoaded">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Ιστορικό Προιόντων
            <span v-if="paginationParams.count > 0" class="tag">{{
              paginationParams.count
            }}</span>
          </div>
        </div>
      </div>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση title, common id"
          @navigate="navigate"
        />

        <NewPagination
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="items.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Χρήστης</th>
                    <th>Περιγραφή</th>
                    <th>Αντικείμενο</th>
                    <th>Ημ/νια</th>
                  </tr>
                </thead>
                <tbody>
                  <revision
                    v-for="(revision, index) in items"
                    :revision="revision"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="revision.id"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν αρχεία
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="items.length"
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PaginatedList from '@/views/components/PaginatedList';
import Revision from './components/Revision';

export default {
  components: {
    Revision,
  },
  extends: PaginatedList,
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      items: 'products/logs/getData',
      paginationParams: 'products/logs/getPaginationParams',
      isLoadingLogs: 'products/logs/getIsLoading',
      isLoaded: 'products/logs/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getData(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  paginated: 1,
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getData: 'products/logs/getData',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
