<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeModal" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Box Now Πακέτα</p>
        <button class="delete" type="button" @click="closeModal" />
      </header>
      <form @submit.prevent="submit">
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Επέλεξε Αριθμό Πακέτων *</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model.number="parcelsCount" name="parcelsCount">
                  <option
                    v-for="option in parcelsCountOptions"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import FormSubmit from '@/views/components/FormSubmit';

export default {
  name: 'BoxnowParcelsModal',
  components: {
    FormSubmit,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parcelsCount: 1,
      parcelsCountOptions: [1, 2, 3, 4, 5],
      isSaving: false,
    };
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        this.$emit('submit', { parcelsCount: this.parcelsCount });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isSaving = false;
      }
    },
    reset() {
      this.parcelsCount = 1;

      this.$validator.reset();
    },
    closeModal() {
      this.$emit('closeModal');

      this.reset();
    },
  },
};
</script>
