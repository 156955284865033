<template>
  <div class="print">
    <!-- START for header with orders' ID, DATE, STAUS, CONDITION -->
    <div class="columns is-multiline">
      <div class="column is-full">
        <nav class="level">
          <img src="@/assets/logo_gray.png" alt="logo" class="logo" />
          <div class="level-item has-text-centered">
            <div>
              <p class="heading print__heading-type">Τυπος</p>
              <p class="title">
                <UserType
                  :item="order"
                  class="is-white"
                  relationship="orderable_type"
                />
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Common ID</p>
              <p class="title">{{ order.common_id }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Ημερομηνια</p>
              <p class="title">{{ order.created_at | timestamp }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Status</p>
              <p class="title">
                <span v-if="isPaid" class="tag is-medium is-primary">
                  Πληρωμένη
                </span>
                <span v-else class="tag is-medium is-white">Απλήρωτη</span>
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Κατασταση</p>
              <p class="title">
                <span v-if="isDeleted" :class="classes">Ακυρωμένη</span>
                <span v-else-if="isUnfinished" :class="classes">
                  Ανολοκλήρωτη
                </span>
                <span v-else-if="isNew" :class="classes">Νέα</span>
                <span v-else :class="classes">{{ status.title }}</span>
              </p>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <!-- END for header-->

    <div class="print__divider" />

    <div class="columns is-multiline">
      <!-- STARTS for 1st column that contains orders BARCODE and PAYMENT/SEND METHODS -->
      <div class="column">
        <barcode :value="order.common_id" width="2" height="50">
          Δεν υπάρχει barcode για αυτό το προϊόν
        </barcode>

        <div v-if="hasCheckoutSendMethods" class="is-size-4">
          <p><b>Μέθοδος Πληρωμής: </b> {{ order.checkout_method.title }}</p>
          <p><b>Μέθοδος Αποστολής: </b> {{ order.send_method.title }}</p>
        </div>
      </div>
      <!-- END for the 1st column-->

      <!-- START fro 2nd column that contains orders INVOICE TYPE, ORDERABLE CUSTOMER INFO and INVOICE DETAILS  -->
      <div class="column is-size-4">
        <p><b>Τύπος Παραστατικού:</b> {{ invoiceType.label }}</p>
        <p><b>ΣΤΟΙΧΕΙΑ ΠΕΛΑΤΗ</b></p>
        <p>
          <b>
            {{ order.orderable.customer.firstName }}
            {{ order.orderable.customer.lastName }}
          </b>
        </p>
        <b> E-mail: </b> {{ order.email }}

        <div v-if="order.invoice">
          <p><b>ΣΤΟΙΧΕΙΑ ΤΙΜΟΛΟΓΗΣΗΣ:</b></p>
          <p><b>Εταιρεία: </b> {{ order.invoice.company }}</p>
          <p><b>Ιδιότητα: </b> {{ order.invoice.profession }}</p>
          <p><b> ΑΦΜ: </b> {{ order.invoice.vat_number }}</p>
          <p><b> ΔΟΥ: </b> {{ vatOffice }}</p>
          <p>
            <b>{{ order.invoice.firstName }} {{ order.invoice.lastName }}</b>
          </p>
          <p>
            <b>{{ order.invoice.street }} {{ order.invoice.streetNumber }}</b>
          </p>
          <p>
            <b>{{ order.invoice.postcode }}, {{ order.invoice.city }}</b>
          </p>
          <p>
            <b>{{ order.invoice.region }} {{ order.invoice.country }}</b>
          </p>
          <p><b> Κινητό: </b> {{ order.invoice.mobile }}</p>
          <p><b> Τηλέφωνο: </b> {{ order.invoice.telephone }}</p>
        </div>
      </div>
      <!-- END for 2nd column-->

      <!-- START for 3rd column that contains orders ADDRESS DETAILS-->
      <div v-if="hasAddress" class="column is-size-4">
        <div class="print__address">
          <p><b>ΣΤΟΙΧΕΙΑ ΠΑΡΑΛΗΠΤΗ</b></p>
          <p>
            {{ order.send_address.firstName }}
            {{ order.send_address.lastName }}
          </p>
          <p>
            {{ order.send_address.street }}
            {{ order.send_address.streetNumber }},
            {{ order.send_address.postcode }}, {{ order.send_address.city }}
          </p>
          <p>
            {{ order.send_address.region }},
            {{ order.send_address.country }}
          </p>
          <p v-if="order.send_address.telephone">
            Τηλέφωνο: {{ order.send_address.telephone }}
          </p>
          <p v-if="order.send_address.mobile">
            Κινητό: {{ order.send_address.mobile }}
          </p>
        </div>
      </div>
      <!-- END for 3rd column-->
    </div>

    <!-- START for orders' PRODUCTS and COSTS -->
    <div class="columns">
      <div class="column">
        <div class="card">
          <div v-if="hasItems">
            <table class="table is-bordered is-striped is-fullwidth">
              <thead>
                <th>#</th>
                <th>Φωτό</th>
                <th>Barcode</th>
                <th>Προιόν</th>
                <th>Color/Size</th>
                <th>Stock</th>
                <th class="hidden-print">Εμφανές</th>
                <th>Π.Λ. Τιμή (&euro;)</th>
                <th>Έκπτωση (%)</th>
                <th>Τελική Τιμή (&euro;)</th>
                <th>Φ.Π.Α. (%)</th>
                <th>Ποσότητα</th>
                <th>Σύνολο (&euro;)</th>
              </thead>
              <tbody>
                <product
                  v-for="(product, index) in order.products"
                  :key="index"
                  :product="product"
                  :index="index"
                  :is-editing="false"
                />
                <product
                  v-for="(product, index) in order.gifts"
                  :key="index + 'gift'"
                  :product="product"
                  :index="order.products.length"
                  :is-editing="false"
                  :is-gift-action-gift="true"
                />
              </tbody>
            </table>

            <logistics
              :order="order"
              :has-loyalty="hasLoyalty"
              :loyalty="loyalty"
            />
          </div>

          <div v-else class="has-text-centered">
            <i class="fa fa-shopping-basket" />
            <h4 class="title is-4">
              Το καλάθι είναι άδειο
            </h4>
          </div>
        </div>
      </div>
    </div>
    <!-- END for orders' PRODUCTS and COSTS -->

    <!-- START for orders' NOTES -->
    <div class="columns is-multiline">
      <div v-if="order.orderable.customer" class=" column is-4 info">
        <div class="columns is-multiline">
          <div class="column is-12">
            <customer-info :customer="order.orderable.customer" />
          </div>

          <div v-if="order.is_gift" class="column is-12">
            <label class="label notification__title">Πληροφορίες Δώρου</label>

            <div class="info__inner">
              <div class="card-content">
                <div>
                  <label class="label">Συσκευασία</label>
                  Συσκευασία Δώρου
                </div>
                <div v-if="order.gift_wishes">
                  <label class="label">Ευχητήριο Μήνυμα</label>
                  {{ order.gift_wishes }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasNotes" class=" column is-4 info">
        <div class="columns is-multiline">
          <div v-if="order.customer_notes_courier" class="column is-12">
            <label class="label notification__title">Σχόλια για Courier</label>
            <div class="info__inner">
              <div
                class="
              card-content"
              >
                {{ order.customer_notes_courier }}
              </div>
            </div>
          </div>

          <div v-if="order.customer_notes" class="column is-12">
            <label class="label notification__title">
              Σχόλια για Παραγγελία
            </label>

            <div class="info__inner">
              <div class="card-content">
                {{ order.customer_notes }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasAdminNotes" class=" column is-4 info">
        <label class="label">Σημειώσεις Διαχειριστή</label>

        <div class="info__inner">
          <div class="card-content info__inner--admin">
            <div
              v-for="note in order.notes"
              :key="note.id"
              :note="note"
              class="media-content"
            >
              <div class="content">
                <p>
                  <strong>{{ note.user.name }}</strong>
                  <small>{{ note.created_at | timestamp }}</small>
                  <br />
                  {{ note.comment }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END for orders' GIFT-WISHES -->
</template>

<script>
import VueBarcode from 'vue-barcode';
import classnames from 'classnames';
import { get, isEmpty, last } from 'lodash';
import UserType from '@/views/components/UserType';
import CustomerInfo from '@/views/components/CustomerInfo';
import Logistics from '../Logistics';
import Product from '../Product';

export default {
  components: {
    Logistics,
    Product,
    barcode: VueBarcode,
    UserType,
    CustomerInfo,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    vatOffices: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      invoiceTypes: [
        {
          key: 'invoice',
          label: 'Τιμολόγιο',
        },
        {
          key: 'no_invoice',
          label: 'Απόδειξη',
        },
      ],
    };
  },

  computed: {
    hasItems() {
      return this.order.products && this.order.products.length > 0;
    },

    hasAddress() {
      return this.order.send_address;
    },

    hasNotes() {
      const hasCourier = isEmpty(this.order.customer_notes_courier);
      const hasNotes = isEmpty(this.order.customer_notes);
      const hasGift = isEmpty(this.order.gift_wishes);

      return !(hasCourier && hasNotes && hasGift);
    },

    hasAdminNotes() {
      return this.order.notes && this.order.notes.length > 0;
    },

    hasCheckoutSendMethods() {
      return (
        this.order.checkout_method &&
        this.order.checkout_method.title &&
        this.order.send_method &&
        this.order.send_method.title
      );
    },

    isDeleted() {
      return this.order.deleted_at !== null;
    },

    isUnfinished() {
      return this.order.is_unfinished;
    },

    invoiceType() {
      return this.order.invoice
        ? {
            key: 'invoice',
            label: 'Τιμολόγιο',
          }
        : {
            key: 'no_invoice',
            label: 'Απόδειξη',
          };
    },

    vatOffice() {
      if (!isEmpty(this.order.invoice) && !isEmpty(this.vatOffices)) {
        const vatId = Number(this.order.invoice.vat_office_id);

        const selectedVatOffice = this.vatOffices.find(
          vatOffice => vatOffice.id === vatId,
        );

        return !isEmpty(selectedVatOffice) ? selectedVatOffice.title : '-';
      }

      return '-';
    },

    isPaid() {
      if (
        ['iris', 'googlePay_applePay'].includes(this.order.checkout_method.slug)
      ) {
        return this.order.viva_status === 'F';
      }

      return !!this.order.transaction;
    },

    isNew() {
      return get(this, 'status.slug', 'new') === 'new';
    },

    status() {
      return last(this.order.statuses);
    },

    customer() {
      return isEmpty(this.order.customer);
    },

    loyalty() {
      return this.order.orderable_type === 'App\\Profile'
        ? this.order.orderable.customer.loyalty
        : {};
    },

    hasLoyalty() {
      return !isEmpty(this.loyalty);
    },

    classes() {
      if (this.isDeleted || this.isUnfinished) {
        return 'tag is-medium is-danger';
      }

      if (this.isNew) {
        return 'tag is-medium is-warning';
      }

      return classnames('tag is-medium', {
        'is-primary': this.status.slug === 'for_delivery',
        'is-info': this.status.slug === 'confirm',
        'is-white': !['for_delivery', 'new', 'confirm'].includes(
          this.status.slug,
        ),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.fa {
  &.fa-shopping-basket {
    font-size: 40px;
    margin-bottom: 1rem;
  }
}

.type.is-white {
  background-color: white;
  border-color: transparent;
}

.logo {
  width: 100px;
}

::v-deep .badge.is-product {
  background-color: #cacaca;
}

::v-deep a {
  color: #000000;
}

::v-deep .tag {
  background-color: #cacaca !important;
}

::v-deep .info {
  .card-content {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
  }

  &__inner {
    background-color: #ffffff;
    border-radius: 4px;
    &--admin {
      padding-top: 6px;
    }
  }

  .content {
    margin-top: 10px;
  }
}

@media print {
  ::v-deep .vue-barcode-element {
    margin-left: -12px;
    max-width: 150px;
  }

  .print {
    page-break-before: always;
    page-break-after: always;
  }
  // The page breake stop working if we add ::v-deep

  ::v-deep .print {
    &__divider {
      border-bottom: 1px solid #dbdbdb;
      margin: 10px 0;
      width: 100%;

      &--notes {
        border-bottom: 1px solid #dbdbdb;
        margin: -10px 0 30px;
        width: 100%;
      }
    }

    &__heading-type {
      margin-top: 9px;
      margin-bottom: -3px;
    }
  }

  ::v-deep .info .card {
    margin-bottom: 0;
  }
}
</style>
