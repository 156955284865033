<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Επεξεργασία Μεγέθους
                  <span class="tag">
                    <strong>{{ model.title }}</strong>
                  </span>
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.sizes.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.sizes.add' }"
                      class="button"
                    >
                      <span class="icon is-small"><i class="fa fa-plus"/></span
                      ><span>Προσθήκη</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="columns">
                <div class="column is-3">
                  <div class="field">
                    <label class="label">Σειρά *</label>
                    <div class="control">
                      <input
                        v-validate="'required'"
                        v-model.number.trim="newSize.order"
                        class="input"
                        type="number"
                        step="1"
                        min="1"
                        name="title"
                      />
                      <p v-show="errors.has('order')" class="help is-danger">
                        Εισάγετε σειρά
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column is-9">
                  <div class="field">
                    <label class="label">Ονομασία *</label>
                    <div class="control">
                      <input
                        v-validate="'required'"
                        v-model.trim="newSize.title"
                        class="input"
                        type="text"
                        name="title"
                      />
                      <p v-show="errors.has('title')" class="help is-danger">
                        Εισάγετε ονομασία
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column is-one-quarter">
        <ExistingSizes :items="sizes" :item="model" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingSizes from '../../components/ExistingSizes';

export default {
  components: {
    EditMetaDetails,
    EditGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingSizes,
  },
  extends: AddItem,
  data() {
    return {
      newSize: {},
    };
  },
  computed: {
    ...mapGetters({
      model: 'sizes/edit/getSize',
      sizes: 'sizes/list/getSizes',
      isLoadingModel: 'sizes/edit/getIsLoading',
      isLoadingItems: 'sizes/list/getIsLoading',
    }),
  },
  watch: {
    $route: 'fetchData',
    model: {
      handler(newVal) {
        this.newSize = _pick(newVal, ['title', 'order']);
      },
      deep: true,
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getSize: 'sizes/edit/getSize',
      updateSize: 'sizes/edit/updateSize',
      getSizes: 'sizes/list/getSizes',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getSize({ uuid: this.$route.params.uuid }),
          this.getSizes({ uuid: this.$route.params.uuid }),
        ]);
      } catch (err) {
        this.$router.push('/error').catch(e => console.log(e));
      }
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateSize({
          uuid: this.model.uuid,
          size: this.newSize,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το μέγεθος αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newSize = _pick(this.model, ['title', 'order']);

      this.$validator.reset();
    },
  },
};
</script>
