<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Ομάδα Φίλτρων
                  <router-link
                    :to="{
                      name: 'products.filterGroups.edit',
                      params: { uuid: $route.params.uuid },
                    }"
                    class="tag"
                  >
                    {{ filterGroup.title }}
                  </router-link>
                  Επεξεργασία Φίλτρου
                  <span class="tag">
                    <strong>{{ model.title }}</strong>
                  </span>
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <div class="control">
                    <router-link
                      :to="{
                        name: 'products.filterGroups.filters.list',
                        params: { uuid: $route.params.uuid },
                      }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <label class="label">Ονομασία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newFilter.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε ονομασία
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  Ονομασία
                  <span class="icon is-small fa flag-icon flag-icon-gb" />
                </label>
                <div class="control">
                  <input
                    v-model.trim="newFilter.title_en"
                    class="input"
                    type="text"
                    name="title_en"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">Προβολή *</label>
                <div class="control">
                  <div class="select select is-fullwidth">
                    <select
                      v-validate="'required'"
                      v-model.trim="newFilter.active"
                      name="active"
                    >
                      <option :value="true">Ναί</option>
                      <option :value="false">Όχι</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column">
        <ExistingFilters :items="items" :filter-group="filterGroup" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingFilters from '../../components/ExistingFilters';

export default {
  components: {
    EditMetaDetails,
    EditGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingFilters,
  },
  extends: AddItem,
  data() {
    return {
      newFilter: {},
    };
  },
  computed: {
    ...mapGetters({
      filterGroup: 'filterGroups/edit/getFilterGroup',
      model: 'filters/edit/getFilter',
      items: 'filters/all/getFilters',
      isLoadingModel: 'filters/edit/getIsLoading',
      isLoadingItems: 'filters/list/getIsLoading',
    }),
  },
  watch: {
    $route: 'fetchData',
    model: {
      handler(newVal) {
        this.newFilter = _pick(newVal, ['title', 'title_en', 'active']);
      },
      deep: true,
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getFilterGroup: 'filterGroups/edit/getFilterGroup',
      getFilter: 'filters/edit/getFilter',
      updateFilter: 'filters/edit/updateFilter',
      getFilters: 'filters/all/getFilters',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getFilterGroup({ uuid: this.$route.params.uuid }),
          this.getFilter({ uuid: this.$route.params.filter }),
          this.getFilters({ uuid: this.$route.params.uuid }),
        ]);
      } catch (err) {
        this.$router.push('/error').catch(e => console.log(e));
      }
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateFilter({
          uuid: this.model.uuid,
          newFilter: {
            ...this.newFilter,
            filter_group_id: this.filterGroup.id,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το φίλτρο αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newFilter = _pick(this.model, ['title', 'title_en', 'active']);

      this.$validator.reset();
    },
  },
};
</script>

<style scoped>
.flag-icon-gb {
  margin-right: 5px;
}
</style>
