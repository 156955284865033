<template>
  <LoadingContainer :is-loading="isLoading">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Κατηγορίες Προιόντων
          </div>
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'products.categories.add' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-plus"/></span
                ><span>Προσθήκη Top Κατηγορίας</span>
              </router-link>
            </div>
            <div class="control">
              <button class="button" type="button" @click="handleERPSync">
                <span class="icon is-small">
                  <i class="fa fa-cloud-download" />
                </span>
                <span>ERP Sync</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="category" class="card-content category-card">
        <h6 class="title is-6">
          {{ category.type | capitalize }} Κατηγορία: {{ category.text }}
        </h6>
        <div class="buttons">
          <span class="button" @click="editItem">
            <span class="icon is-small"><i class="fa fa-pencil"/></span>
            <span>Επεξεργασία</span>
          </span>
          <span v-if="category.type !== 'low'" class="button" @click="addChild">
            <span class="icon is-small"><i class="fa fa-plus"/></span>
            <span>Υποκατηγορία</span>
          </span>
        </div>
      </div>
      <div class="card-content">
        <v-jstree :data="categories" @item-click="itemClick" />
      </div>
    </div>

    <SyncModal :is-open="isSyncing" />
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _has from 'lodash/has';
import VJstree from 'vue-jstree';
import SyncModal from '@/views/components/SyncModal';
import LoadingContainer from '@/views/components/LoadingContainer';

export default {
  components: {
    VJstree,
    SyncModal,
    LoadingContainer,
  },
  data() {
    return {
      isSyncing: false,
      category: null,
    };
  },
  computed: {
    ...mapGetters({
      categories: 'categories/list/getCategoriesTree',
      categoriesPerType: 'categories/list/getCategoriesPerType',
      isLoading: 'categories/list/getIsLoading',
    }),
  },
  async created() {
    await this.getCategories();
  },
  methods: {
    ...mapActions({
      getCategories: 'categories/list/getCategories',
      checkForCategoriesAdditions:
        'categories/list/checkForCategoriesAdditions',
    }),
    getCategoryType(item) {
      return Object.keys(this.categoriesPerType).find(type =>
        _has(this.categoriesPerType[type], item.id),
      );
    },
    itemClick({ model }) {
      this.category = {
        ...model,
        type: this.getCategoryType(model),
      };
    },
    editItem() {
      this.$router
        .push({
          name: 'products.categories.edit',
          params: {
            uuid: this.category.id,
          },
        })
        .catch(err => console.log(err));
    },
    addChild() {
      this.$router
        .push({
          name: 'products.categories.add',
          query: {
            parent_id: this.category.id,
          },
        })
        .catch(err => console.log(err));
    },
    async handleERPSync() {
      try {
        this.isSyncing = true;
        await this.checkForCategoriesAdditions();

        await this.getCategories();

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ενημέρωση εκτελέστηκε επιτυχώς!!',
        });
        this.isSyncing = false;
      } catch (err) {
        this.isSyncing = false;

        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 0.8rem;
}

.category-card {
  border-bottom: 1px solid #dbdbdb;
}
</style>
