<template>
  <LoadingContainer :is-loading="isLoading">
    <form @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Κατηγορίες Άρθρων <span class="tag">{{ general.title }}</span>
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link
                  :to="{ name: 'content.postCategories.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list"/></span>
                  <span>Λίστα</span>
                </router-link>
              </div>
              <div class="control">
                <button class="button" @click="askToDeleteItem">
                  <span class="icon is-small"><i class="fa fa-trash"/></span>
                  <span>Διαγραφή</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="field">
            <label class="label">Ονομασία *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model.trim="general.title"
                class="input"
                type="text"
                name="title"
              />
              <p v-show="errors.has('title')" class="help is-danger">
                Εισάγετε ονομασία
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Slug *</label>
            <div class="control">
              <input
                v-validate="'required|regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
                v-model.trim="general.slug"
                class="input"
                type="text"
                name="slug"
              />
              <p v-show="errors.has('slug')" class="help is-danger">
                Εισάγετε έγκυρο slug
              </p>
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard title="Meta">
        <div class="card-content">
          <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
        </div>
      </ToggleableCard>

      <ToggleableCard title="Social Meta">
        <div class="card-content">
          <EditSocialMetaDetails
            :social-meta="socialMeta"
            @updateSocialMeta="updateSocialMeta"
          />
        </div>
      </ToggleableCard>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>

    <confirmation
      :model-name="general.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτής της κατηγορίας;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import LoadingContainer from '@/views/components/LoadingContainer';
import request from '@/utils/request';
import ToggleableCard from '@/views/components/ToggleableCard';
import EditItem from '@/views/components/EditItem';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';

export default {
  components: {
    ToggleableCard,
    LoadingContainer,
    EditMetaDetails,
    EditSocialMetaDetails,
  },
  extends: EditItem,
  data() {
    return {
      meta: {},
      socialMeta: {},
      general: {},
    };
  },
  computed: {
    ...mapGetters({
      model: 'postCategories/edit/getPostCategory',
      isLoading: 'postCategories/edit/getIsLoading',
    }),
  },
  watch: {
    model: {
      handler(newVal) {
        this.general = _pick(newVal, ['title', 'slug']);

        this.meta = _clone(newVal.meta);
        this.socialMeta = _clone(newVal.social_meta);
      },
      deep: true,
    },
  },
  async created() {
    try {
      await this.getPostCategory({ uuid: this.$route.params.uuid });
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getPostCategory: 'postCategories/edit/getPostCategory',
      updatePostCategory: 'postCategories/edit/updatePostCategory',
    }),
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updatePostCategory({
          uuid: this.model.uuid,
          postCategory: {
            ...this.general,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η κατηγορία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = _pick(this.model, ['title', 'slug']);

      this.meta = _clone(this.model.meta);
      this.socialMeta = _clone(this.model.social_meta);

      this.$children.forEach(child => child.$validator.reset());
    },
    updateMeta(meta) {
      this.meta = meta;
    },
    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },
    updateGeneral(general) {
      this.general = general;
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/postCategories/${this.model.uuid}`);
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η κατηγορία διαγράφτηκε',
          });
          this.$router
            .push({ name: 'content.postCategories.list' })
            .catch(err => console.log(err));
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
