<template>
  <form @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          Προσθήκη Gift Action
        </div>
        <div class="card-header-icon">
          <div class="field is-marginless">
            <div class="control">
              <RouterLink
                :to="{ name: 'marketing.giftActions.giftActions.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <!-- COUNT-VALUE SECTION -->

        <div class="columns">
          <!-- COUNT-VALUE MULTISELECT -->

          <div class="column is-one-quarter">
            <div class="field">
              <label class="label">Ποσότητα η Αξία</label>
              <div
                v-for="type in operatorType"
                :key="type.key"
                class=" control radio-control"
              >
                <radio
                  :value="type.key"
                  v-model="countValue"
                  name="operatorType"
                  class="is-inline-block"
                />
                <label>
                  {{ type.label }}
                </label>
              </div>
            </div>
          </div>
          <!-- EOF COUNT-VALUE MULTISELECT -->

          <!-- COUNT-VALUE FIELD -->
          <div class="column">
            <div v-if="countValue === 'count'" class="field">
              <div>
                <label class="label">
                  Τεμάχια*
                </label>
                <div class="control">
                  <input
                    v-validate="'required|excluded:0'"
                    v-model.trim.number="operators.count"
                    class="input"
                    type="number"
                    name="count"
                    min="0"
                    step="1"
                  />
                  <p v-show="errors.has('count')" class="help is-danger">
                    Εισάγετε Τεμάχια
                  </p>
                </div>
              </div>
            </div>
            <div v-if="countValue === 'value'">
              <label class="label">
                Αξία *
              </label>
              <div class="control">
                <input
                  v-validate="'required|excluded:0'"
                  v-model.trim.number="operators.value"
                  class="input"
                  type="number"
                  name="value"
                  min="0"
                  step="0.01"
                />
                <p v-show="errors.has('value')" class="help is-danger">
                  Εισάγετε Αξία
                </p>
              </div>
            </div>
          </div>
          <!-- COUNT-VALUE FIELD -->
        </div>
        <!--EOF COUNT-VALUE SECTION -->

        <!-- SINGLE MODEL CHOICE -->
        <section class="field">
          <div class="field">
            <label class="label">Συνθήκη *</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select
                  v-model="operators.models[0].type"
                  name="active"
                  @input="
                    e => {
                      cleanModel(e);
                    }
                  "
                >
                  <option :value="'product'">Προϊόν</option>
                  <option :value="'category'">Κατηγορία</option>
                  <option :value="'brand'">Μάρκα</option>
                  <option :value="'collection'">Σειρά</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <span v-if="operators.models[0].type === 'product'">
                Προϊόν *
              </span>
              <span v-if="operators.models[0].type === 'category'">
                Κατηγορία *
              </span>
              <span v-if="operators.models[0].type === 'brand'">
                Μάρκα *
              </span>
              <span v-if="operators.models[0].type === 'collection'">
                Σειρά *
              </span>
            </label>

            <div class="control">
              <div v-if="operators.models[0].type === 'product'">
                <products-search
                  :required="true"
                  :products="operators.models[0].id"
                  @selectProduct="
                    selectedOption => handleSelectBaseProduct(selectedOption, 0)
                  "
                  @removeProduct="handleRemoveBaseProduct(0)"
                />
                <p v-show="errors.has('products')" class="help is-danger">
                  Επιλέξτε προιόν
                </p>
              </div>

              <div v-if="operators.models[0].type === 'category'">
                <categories-search
                  :required="true"
                  :category="operators.models[0].id"
                  @select-category="
                    selectedOption => handleSelectBaseProduct(selectedOption, 0)
                  "
                  @removeCategory="handleRemoveBaseProduct(0)"
                />
                <p v-show="errors.has('category')" class="help is-danger">
                  Επιλέξτε category
                </p>
              </div>

              <div v-if="operators.models[0].type === 'brand'">
                <brands-search
                  :required="true"
                  :brand="operators.models[0].id"
                  @selectBrand="
                    selectedOption => handleSelectBaseProduct(selectedOption, 0)
                  "
                  @removeBrand="handleRemoveBaseProduct(0)"
                />
                <p v-show="errors.has('brand')" class="help is-danger">
                  Επιλέξτε brand
                </p>
              </div>

              <div v-if="operators.models[0].type === 'collection'">
                <collections-search
                  :required="true"
                  :collection="operators.models[0].id"
                  @selectCollection="
                    selectedOption => handleSelectBaseProduct(selectedOption, 0)
                  "
                  @removeCollection="handleRemoveBaseProduct(0)"
                />
                <p v-show="errors.has('collection')" class="help is-danger">
                  Επιλέξτε σειρά
                </p>
              </div>
            </div>
          </div>
        </section>
        <!-- EOF SINGLE MODEL CHOICE -->

        <!-- MULTIPLE MODELS -->
        <div class="field columns">
          <div class="control column is-one-quarter">
            <Button type="button" class="add-button" @click="addNewModel">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 511.574438 511.620954"
                preserveAspectRatio="xMidYMid meet"
                class="svg"
              >
                <metadata>
                  Created by Potrace Professional 1.1m
                </metadata>
                <g
                  transform="translate(-0.212781,511.833735) rotate(-360.00) scale(0.095238,-0.095238)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M2570 5360 c-179 -44 -328 -210 -355 -396 -5 -36 -10 -441 -10 -899
l0 -833 -31 -30 -30 -31 -833 0 c-458 0 -863 -5 -899 -10 -202 -29 -369 -193
-403 -397 -44 -258 142 -511 403 -549 36 -5 441 -10 899 -10 l833 0 30 -31 31
-30 0 -833 c0 -458 5 -863 10 -899 29 -202 193 -369 397 -403 258 -44 511 142
549 403 5 36 10 441 10 899 l0 833 31 30 30 31 833 0 c458 0 863 5 899 10 261
38 447 291 403 549 -34 204 -201 368 -403 397 -36 5 -441 10 -899 10 l-833 0
-30 31 -31 30 0 833 c0 458 -5 863 -10 899 -27 189 -177 353 -360 396 -80 19
-151 19 -231 0z"
                  />
                </g>
              </svg>
              <span>
                Προσθήκη εναλλακτικής η συμπληρωματικής συνθήκης
              </span>
            </Button>
          </div>
        </div>

        <!-- AND/OR/IN -->
        <div v-if="operators.models.length > 1" class="column is-one-quarter">
          <div class="field">
            <label class="label">
              Εναλλακτική ή Συμπληρωματική Συνθήκη;
            </label>
            <div
              v-for="condition in conditions"
              :key="condition.key"
              class=" control radio-control"
            >
              <radio
                :value="condition.key"
                v-model="operators.operator"
                name="condition"
                class="is-inline-block"
              />
              <label>
                {{ condition.label }}
              </label>
            </div>
          </div>
        </div>
        <!-- EOF AND/OR -->

        <!-- MULTIPLE MODEL FIELDS -->
        <section
          v-for="(section, index) in operators.models.slice(1)"
          :key="index"
          class="field model-item"
        >
          <div class="field">
            <label class="label">Συνθήκη </label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select
                  v-model="operators.models[index + 1].type"
                  name="active"
                  @input="
                    e => {
                      cleanModel(e, index + 1);
                    }
                  "
                >
                  <option :value="'product'">Προϊόν</option>
                  <option :value="'category'">Κατηγορία</option>
                  <option :value="'brand'">Μάρκα</option>
                  <option :value="'collection'">Σειρά</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">
              <span v-if="operators.models[index + 1].type === 'product'">
                Προϊόν *
              </span>
              <span v-if="operators.models[index + 1].type === 'category'">
                Κατηγορία *
              </span>
              <span v-if="operators.models[index + 1].type === 'brand'">
                Μάρκα *
              </span>
              <span v-if="operators.models[index + 1].type === 'collection'">
                Σειρά *
              </span>
            </label>

            <div class="control">
              <products-search
                v-if="operators.models[index + 1].type === 'product'"
                :required="false"
                :products="operators.models[index + 1].id"
                @selectProduct="
                  selectedOption =>
                    handleSelectBaseProduct(selectedOption, index + 1)
                "
                @removeProduct="handleRemoveBaseProduct(index + 1)"
              />

              <categories-search
                v-if="operators.models[index + 1].type === 'category'"
                :category="operators.models[index + 1].id"
                @select-category="
                  selectedOption =>
                    handleSelectBaseProduct(selectedOption, index + 1)
                "
              />

              <brands-search
                v-if="operators.models[index + 1].type === 'brand'"
                :brand="operators.models[index + 1].id"
                @selectBrand="
                  selectedOption =>
                    handleSelectBaseProduct(selectedOption, index + 1)
                "
                @removeBrand="handleRemoveBaseProduct(index + 1)"
              />

              <collections-search
                v-if="operators.models[index + 1].type === 'collection'"
                :collection="operators.models[index + 1].id"
                @selectCollection="
                  selectedOption =>
                    handleSelectBaseProduct(selectedOption, index + 1)
                "
                @removeCollection="handleRemoveBaseProduct(index + 1)"
              />
            </div>
          </div>
        </section>
        <!--EOF MULTIPLE MODEL FIELDS -->
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          Προσθήκη Δώρου
        </div>
        <div class="card-header-icon">
          <div class="field is-marginless">
            <div class="control">
              <RouterLink
                :to="{ name: 'marketing.giftActions.giftActions.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Τίτλος *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="newGiftAction.title"
                  class="input"
                  type="text"
                  name="title"
                />
                <p v-show="errors.has('title')" class="help is-danger">
                  Εισάγετε τίτλο
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">E-shop URL *</label>
              <div class="control">
                <input
                  v-validate="'url|required'"
                  v-model.trim="newGiftAction.link"
                  name="link"
                  class="input"
                  type="text"
                />
                <p v-show="errors.has('link')" class="help is-danger">
                  Εισάγετε έγκυρο url
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Ενεργό *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="newGiftAction.active" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <label class="label">
              Ημερομηνία Εκκίνησης *
            </label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <Datepicker
                  v-validate="'required'"
                  v-model="newGiftAction.date_start"
                  :disabled-dates="disabledDates"
                  :open-date="new Date()"
                  name="date_start"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
                <p v-show="errors.has('date_start')" class="help is-danger">
                  Εισάγετε ημ/νία εκκίνησης
                </p>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <label class="label">
              Ημερομηνία Λήξης *
            </label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <datepicker
                  v-validate="'required'"
                  :disabled-date="disableDate"
                  v-model="newGiftAction.date_end"
                  :open-date="new Date()"
                  name="date_end"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
                <p v-show="errors.has('date_end')" class="help is-danger">
                  Εισάγετε ημ/νία λήξης
                </p>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Gift Action Description *</label>
              <div class="control">
                <textarea
                  v-validate="'required'"
                  v-model.trim="newGiftAction.description"
                  class="textarea"
                  name="description"
                />
                <p v-show="errors.has('description')" class="help is-danger">
                  Εισάγετε περιγραφή για το Gift Action.
                </p>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <div class="control">
              <label class="label">Gifts</label>
              <div class="control">
                <gifts-search
                  :required="true"
                  :gifts="newGiftAction.products"
                  @selectProduct="handleSelectProduct"
                  @removeProduct="handleRemoveProduct"
                />
                <p v-show="errors.has('gifts')" class="help is-danger">
                  Επιλέξτε gift
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-content">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import request from '@/utils/request';
import moment from 'moment';
import AddItem from '@/views/components/AddItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import ProductsSearch from '@/views/components/ProductsSearch';
import BrandsSearch from '@/views/components/BrandsSearch';
import Datepicker from 'vuejs-datepicker';
import EditOperators from '@/views/components/EditOperators';
import CollectionsSearch from '@/views/components/CollectionsSearch';
import GiftsSearch from '@/views/components/GiftsSearch';
import CategoriesSearch from '@/views/components/Filters/components/Filter/components/CategoriesSearch';

export default {
  components: {
    ToggleableCard,
    ProductsSearch,
    Datepicker,
    EditOperators,
    BrandsSearch,
    CollectionsSearch,
    GiftsSearch,
    CategoriesSearch,
  },

  extends: AddItem,

  data() {
    return {
      conditions: [
        {
          key: 'or',
          label: 'Εναλλακτική (ή)',
        },
        {
          key: 'and',
          label: 'Συμπληρωματική (και)',
        },
        {
          key: 'in',
          label: 'Μέσα (in)',
        },
      ],
      operatorType: [
        {
          key: 'count',
          label: 'Ποσότητα Προϊόντων',
        },
        {
          key: 'value',
          label: 'Χρηματική Αξία',
        },
      ],
      countValue: 'count',
      operators: {
        models: [
          {
            type: 'product',
            id: [],
          },
        ],
        operator: 'or',
        value: 1,
        count: 1,
      },
      newGiftAction: {
        title: '',
        link: '',
        active: false,
        date_start: moment().toDate(),
        date_end: null,
        products: [],
        description: '',
      },
      disabledDates: new Date(),
    };
  },

  methods: {
    cleanModel(e, index = 0) {
      const newModels = [
        ...this.operators.models.slice(0, index),
        { type: e.target.value, id: [] },
        ...this.operators.models.slice(index + 1, this.operators.models.length),
      ];

      this.operators = {
        ...this.operators,
        models: [...newModels],
      };
    },

    addNewModel() {
      this.operators = {
        ...this.operators,
        models: [...this.operators.models, { id: null, type: 'product' }],
      };
    },

    handleSelectBaseProduct(selectedOption, index) {
      const newModels = [
        ...this.operators.models.slice(0, index),
        { ...this.operators.models[index], id: [selectedOption] },
        ...this.operators.models.slice(index + 1, this.operators.models.length),
      ];

      this.operators = {
        ...this.operators,
        models: [...newModels],
      };
    },

    handleRemoveBaseProduct(index) {
      const newModels = [
        ...this.operators.models.slice(0, index),
        { ...this.operators.models[index], id: null },
        ...this.operators.models.slice(index + 1, this.operators.models.length),
      ];

      this.operators = {
        ...this.operators,
        models: [...newModels],
      };
    },

    handleSelectProduct(product) {
      this.newGiftAction = {
        ...this.newGiftAction,
        products: [...(this.newGiftAction.products || []), product],
      };
    },

    handleRemoveProduct(product) {
      this.newGiftAction = {
        ...this.newGiftAction,
        products: this.newGiftAction.products.filter(
          ({ id }) => id !== product.id,
        ),
      };
    },

    disableDate(date) {
      if (
        moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') <
        moment()
          .add(2, 'days')
          .format('YYYY-MM-DD')
      ) {
        return true;
      }
      return false;
    },

    formatDateForApi(date) {
      return moment(date, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('YYYY-MM-DD');
    },

    removeCountOrValue(object) {
      if (this.countValue === 'count') {
        const { value, ...operators } = object;
        return operators;
      }

      if (this.countValue === 'value') {
        const { count, ...operators } = object;
        return operators;
      }

      return object;
    },

    formatGiftAction() {
      const newModels = this.operators.models
        .filter(model => model.id && model.type)
        .map(model => ({
          ...model,
          id: model.id[0].uuid,
        }));

      const formedGiftAction = {
        ...this.newGiftAction,
        operators: this.removeCountOrValue({
          ...this.operators,
          models: [...newModels],
        }),
      };

      return formedGiftAction;
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/giftActions', {
          ...this.formatGiftAction(),
          date_start: this.formatDateForApi(this.newGiftAction.date_start),
          date_end: this.formatDateForApi(this.newGiftAction.date_end),
          products: this.newGiftAction.products.map(item => item.id),
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router
          .push({ name: 'marketing.giftActions.giftActions.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'H ενέργεια δώρου αποθηκεύτηκε',
        });
      } catch (err) {
        console.log(err);
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newGiftAction = {
        title: '',
        link: '',
        active: false,
        date_start: moment().toDate(),
        date_end: '',
      };

      this.$validator.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.add-button {
  display: flex;
  align-items: center;
  text-align: left;
  background-color: #fff;
  border: 0;
  cursor: pointer;

  .svg {
    flex: 1 0 25px;
    margin-right: 10px;
  }
}

.model-item {
  padding-bottom: 10px;
  border-bottom: gray 1px solid;
  margin-bottom: 25px;
}
</style>
