<template>
  <LoadingContainer :is-loading="isLoadingModel">
    <form novalidate @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Γενικά Στοιχεία
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="field">
            <label class="label">FB APP ID *</label>
            <div class="control">
              <textarea-autosize
                v-validate="'required'"
                v-model.trim="general.fb_app_id"
                rows="1"
                class="textarea"
                name="fb_app_id"
              />
              <p v-show="errors.has('fb_app_id')" class="help is-danger">
                Εισάγετε fb_app_id
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Site Name *</label>
            <div class="control">
              <textarea-autosize
                v-validate="'required'"
                v-model.trim="general.og_site_name"
                rows="1"
                class="textarea"
                name="og_site_name"
              />
              <p v-show="errors.has('og_site_name')" class="help is-danger">
                Εισάγετε site name
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Twitter Username *</label>
            <div class="control">
              <textarea-autosize
                v-validate="'required'"
                v-model.trim="general.twitter_site"
                rows="1"
                class="textarea"
                name="twitter_site"
              />
              <p v-show="errors.has('twitter_site')" class="help is-danger">
                Εισάγετε twitter username
              </p>
            </div>
          </div>
        </div>
      </div>

      <edit-photos
        :model="model"
        :photos="photos"
        :is-loading="isLoadingPhotos"
        :can-upload-images="false"
        :can-edit-avatar="false"
        :can-delete-avatar="false"
        @updatePhotos="updatePhotos"
      />

      <ToggleableCard title="Meta">
        <div class="card-content">
          <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
        </div>
      </ToggleableCard>

      <ToggleableCard title="Social Meta">
        <div class="card-content">
          <EditSocialMetaDetails
            :social-meta="socialMeta"
            @updateSocialMeta="updateSocialMeta"
          />
        </div>
      </ToggleableCard>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';
import EditPhotos from '@/views/components/EditPhotos';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';

export default {
  components: {
    EditMetaDetails,
    EditSocialMetaDetails,
    EditGeneralDetails,
    EditPhotos,
    ToggleableCard,
    LoadingContainer,
  },
  extends: AddItem,
  data() {
    return {
      meta: {},
      socialMeta: {},
      general: {},
      photos: [],
      isLoadingPhotos: false,
    };
  },
  computed: {
    ...mapGetters({
      model: 'general/edit/getGeneral',
      isLoadingModel: 'general/edit/getIsLoading',
    }),
  },
  watch: {
    model: {
      handler(newVal) {
        this.general = _pick(newVal, [
          'fb_app_id',
          'og_site_name',
          'twitter_site',
        ]);
        this.meta = _clone(newVal.meta);
        this.socialMeta = _clone(newVal.social_meta);
        this.photos = _clone(this.model.photos);
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      getGeneral: 'general/edit/getGeneral',
      getGeneralPhotos: 'general/edit/getGeneralPhotos',
      updateGeneral: 'general/edit/updateGeneral',
    }),
    async fetchData() {
      try {
        await this.getGeneral();
      } catch (err) {
        this.$router.push('/error').catch(e => console.log(e));
      }
    },
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateGeneral({
          general: {
            ...this.general,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Tα στοιχεία αποθηκεύτηκαν',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = _pick(this.model, [
        'fb_app_id',
        'og_site_name',
        'twitter_site',
      ]);

      this.meta = _clone(this.model.meta);
      this.socialMeta = _clone(this.model.social_meta);
      this.photos = _clone(this.model.photos);

      this.$children.forEach(child => child.$validator.reset());
    },
    updateMeta(meta) {
      this.meta = meta;
    },
    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },
    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;
        await this.getGeneralPhotos();
        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },
  },
};
</script>
