<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Επεξεργασία Χρώματος
                  <span class="tag">
                    <strong>{{ model.title }}</strong>
                  </span>
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.colors.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                  <div class="control">
                    <router-link
                      :to="{
                        name: 'products.colors.add',
                        params: { uuid: $route.params.uuid },
                      }"
                      class="button"
                    >
                      <span class="icon is-small"><i class="fa fa-plus"/></span
                      ><span>Προσθήκη</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <label class="label">Ονομασία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newColor.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε ονομασία
                  </p>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column is-one-quarter">
        <ExistingColors :item="model" :items="items" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingColors from '../../components/ExistingColors';

export default {
  components: {
    EditMetaDetails,
    EditGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingColors,
  },
  extends: AddItem,
  data() {
    return {
      newColor: {},
    };
  },
  computed: {
    ...mapGetters({
      model: 'colors/edit/getColor',
      items: 'colors/list/getData',
      isLoadingModel: 'colors/edit/getIsLoading',
      isLoadingItems: 'colors/list/getIsLoading',
    }),
  },
  watch: {
    $route: 'fetchData',
    model: {
      handler(newVal) {
        this.newColor = _pick(newVal, ['title']);
      },
      deep: true,
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getColor: 'colors/edit/getColor',
      updateColor: 'colors/edit/updateColor',
      getColors: 'colors/list/getColors',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getColor({ uuid: this.$route.params.uuid }),
          this.getColors(),
        ]);
      } catch (err) {
        console.log(err);
        this.$router.push('/error').catch(e => console.log(e));
      }
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateColor({
          uuid: this.model.uuid,
          color: this.newColor,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το χρώμα αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newColor = _pick(this.model, ['title']);

      this.$validator.reset();
    },
  },
};
</script>
