<template>
  <LoadingContainer :is-loading="isLoading">
    <form class="has-sticky-submit" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Banners <span class="tag">Επεξεργασία Banner</span>
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <RouterLink
                  :to="{ name: 'content.banners.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list"/></span>
                  <span>Λίστα</span>
                </RouterLink>
              </div>
              <div class="control">
                <button class="button" @click="askToDeleteItem">
                  <span class="icon is-small"><i class="fa fa-trash"/></span>
                  <span>Διαγραφή</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <AddPhotos
                :title="'Banner'"
                :preview="preview"
                :show-error="errors.has('image')"
                rule="image/webp"
                @addPhotos="handleAddPhotos"
              >
                <template slot="buttons">
                  <div class="field has-addons">
                    <div class="control">
                      <button
                        type="button"
                        class="button is-small"
                        @click="handlePreviousBanner"
                      >
                        <span class="icon is-small">
                          <i class="fa fa-angle-double-left" />
                        </span>
                        <span>Προηγούμενο Banner</span>
                      </button>
                    </div>
                    <div class="control">
                      <button
                        type="button"
                        class="button is-small"
                        @click="handleNextBanner"
                      >
                        <span>Επόμενο Banner</span>
                        <span class="icon is-small">
                          <i class="fa fa-angle-double-right" />
                        </span>
                      </button>
                    </div>
                  </div>
                </template>
              </AddPhotos>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Τίτλος *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="form.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε τίτλο
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Αγγλικός Τίτλος </label>
                <div class="control">
                  <input
                    v-model.trim="form.title_en"
                    class="input"
                    type="text"
                    name="title_en"
                  />
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Link *</label>
                <div class="control">
                  <input
                    v-validate="'required|url'"
                    v-model.trim="form.link"
                    class="input"
                    type="text"
                    name="link"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε link
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Εμφανές *</label>
                <div class="control">
                  <ToggleButton
                    :sync="true"
                    :value="form.active"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="updateActive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickyFormFooter>
        <template v-slot:left>
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </template>
        <template v-slot:right v-if="banner.link">
          <a
            v-tooltip="'Προβολή στο e-shop'"
            :href="banner.link"
            target="_blank"
          >
            Προβολή link στο e-shop <i class="fa fa-arrow-right" />
          </a>
        </template>
      </StickyFormFooter>
    </form>

    <Confirmation
      :model-name="banner.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του banner;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import pick from 'lodash/pick';
import { isEmpty } from 'lodash';
import EditItem from '@/views/components/EditItem';
import toolbarOptions from '@/constants/toolbarOptions';
import AddPhotos from '@/views/components/AddPhotos';
import request from '@/utils/request';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import LoadingContainer from '@/views/components/LoadingContainer';

export default {
  components: {
    LoadingContainer,
    StickyFormFooter,
    AddPhotos,
  },

  extends: EditItem,

  data() {
    return {
      banner: {},
      isLoading: false,
      form: {
        title: '',
        title_en: '',
        link: '',
        active: false,
        image: null,
      },
      preview: '',
      toolbarOptions,
    };
  },

  computed: {
    ...mapGetters({
      banners: 'banners/all/getData',
    }),
  },

  watch: {
    '$route.params.uuid': {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          await this.fetchData();
        }
      },
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    ...mapActions({
      getBanners: 'banners/all/getBanners',
      updateBanner: 'banners/edit/updateBanner',
    }),

    async fetchData() {
      try {
        this.isLoading = true;
        const { data } = await request.get(
          `/banners/${this.$route.params.uuid}`,
        );

        await this.getBanners();

        const { banner } = data;
        this.banner = banner;
        this.form = {
          ...this.form,
          ...pick(this.banner, ['title', 'title_en', 'link', 'active']),
        };
        this.preview = this.banner.path;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (isEmpty(this.preview)) {
          this.errors.add({
            field: 'image',
            msg: 'Image is required',
          });
        }

        if (!isValid || isEmpty(this.preview)) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.updateBanner({ uuid: this.banner.uuid, banner: this.form });

        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'To banner αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.form = {
        ...this.form,
        ...pick(this.banner, ['title', 'title_en', 'link', 'active']),
      };
      this.preview = this.banner.path;

      this.$validator.reset();
      this.errors.remove('image');
    },

    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/banners/${this.banner.uuid}`);
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'To banner διαγράφτηκε',
          });
          this.$router
            .push({ name: 'content.banners.list' })
            .catch(err => console.log(err));
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    updateActive({ value }) {
      this.form = {
        ...this.form,
        active: !!value,
      };
    },

    handleAddPhotos(image) {
      this.form.image = image;

      const reader = new FileReader();

      this.$validator
        .validateAll()
        .then(() => {
          reader.onload = e => {
            this.preview = e.target.result;
            this.errors.remove('image');
          };
          reader.readAsDataURL(image);
        })
        .catch(err => console.log(err));
    },

    handlePreviousBanner() {
      const index = this.banners.findIndex(
        banner => banner.uuid === this.banner.uuid,
      );
      const previousBanner =
        this.banners[index - 1] || this.banners[this.banners.length - 1];

      if (previousBanner) {
        this.$router
          .push({
            name: 'content.banners.edit',
            params: { uuid: previousBanner.uuid },
          })
          .catch(err => console.log(err));
      }
    },

    handleNextBanner() {
      const index = this.banners.findIndex(
        banner => banner.uuid === this.banner.uuid,
      );
      const nextBanner = this.banners[index + 1] || this.banners[0];

      if (nextBanner) {
        this.$router
          .push({
            name: 'content.banners.edit',
            params: { uuid: nextBanner.uuid },
          })
          .catch(err => console.log(err));
      }
    },
  },
};
</script>
