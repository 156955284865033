<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Week's Deal
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Ενεργό</label>
              <div class="control">
                <ToggleButton
                  :sync="true"
                  v-model="form.active"
                  :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                  :width="30"
                  :height="15"
                />
              </div>
            </div>
          </div>

          <div class="column">
            <label class="label">Ημερομηνία Λήξης *</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <Datepicker
                  v-validate="'required'"
                  v-model="form.expiration_date"
                  name="expiration_date"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
                <p v-if="errors.has('expiration_date')" class="help is-danger">
                  Εισάγετε ημερομηνία λήξης
                </p>
              </div>
              <div v-if="form.expiration_date" class="control">
                <button class="button" type="button" @click="clearDate">
                  <span class="icon"><i class="fa fa-times"/></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Προϊόν *</label>
              <products-search
                v-validate="'required'"
                v-model="form.product_id"
                data-vv-name="product_id"
                @select-product="handleSelectProduct"
              />
              <p v-if="errors.has('product_id')" class="help is-danger">
                Επιλέξτε προϊόν
              </p>
            </div>
          </div>
        </div>
        <div v-if="hasProduct">
          <table class="table is-bordered is-striped is-fullwidth">
            <thead>
              <th>Φωτό</th>
              <th>Προιόν</th>
              <th>Π.Λ. Τιμή (&euro;)</th>
              <th>Έκπτωση (%)</th>
              <th>Τελική Τιμή (&euro;)</th>
              <th>Φ.Π.Α. (%)</th>
              <th class="hidden-print">
                Ενέργειες
              </th>
            </thead>
            <tbody>
              <product
                :product="newProduct"
                :is-editing="true"
                @delete-item="handleDeleteItem"
              />
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import Datepicker from 'vuejs-datepicker';
import request from '@/utils/request';
import FormSubmit from '@/views/components/FormSubmit';
import Notification from '@/views/components/Notification';
import Product from './components/Product';
import ProductsSearch from './components/ProductsSearch';

export default {
  components: {
    Notification,
    Datepicker,
    ProductsSearch,
    Product,
    FormSubmit,
  },
  data() {
    return {
      form: {
        active: false,
        expiration_date: null,
        product_id: null,
      },
      deal: {
        active: false,
        expiration_date: null,
        product_id: null,
      },
      newProduct: {},
      product: {},
      isSaving: false,
    };
  },
  computed: {
    hasProduct() {
      return !isEmpty(this.newProduct);
    },
  },
  async created() {
    try {
      const response = await request.get('/weeks-deal');

      const { deal } = response.data;

      if (deal) {
        this.product = response.data.deal.product;
        this.newProduct = clone(this.product);

        this.deal = {
          ...response.data.deal,
          product_id: this.newProduct.id,
        };
      }

      this.form = clone(this.deal);
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.form = {
          ...this.form,
          expiration_date: moment(this.form.expiration_date).format(
            'YYYY-MM-DD',
          ),
        };

        this.isSaving = true;
        await request.post('/weeks-deal', this.form);

        this.deal = clone(this.form);
        this.product = clone(this.newProduct);

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η προσφορά αποθηκεύτηκε',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isSaving = false;
      }
    },
    clearDate() {
      this.form.expiration_date = null;
    },
    handleSelectProduct(product) {
      this.newProduct = product;
      this.form.product_id = product.id;
    },
    handleDeleteItem() {
      this.newProduct = {};
      this.form.product_id = {};
    },
    reset() {
      this.form = clone(this.deal);
      this.newProduct = clone(this.product);
      this.$validator.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  margin: 0;
}

.fa {
  &.fa-shopping-basket {
    font-size: 40px;
    margin-bottom: 1rem;
  }
}
</style>
