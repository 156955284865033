<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Σελίδες Κειμένου <span class="tag">Προσθήκη Σελίδας</span>
        </div>
      </div>
      <div class="card-header-icon">
        <router-link :to="{ name: 'content.pages.list' }" class="button">
          <span class="icon is-small"><i class="fa fa-list"/></span>
          <span>Λίστα</span>
        </router-link>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Ονομασία *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="newPage.title"
              class="input"
              type="text"
              name="title"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε ονομασία
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Slug *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="newPage.slug"
              class="input"
              type="text"
              name="slug"
            />
            <p v-show="errors.has('slug')" class="help is-danger">
              Εισάγετε slug
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Περιγραφή *</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model.trim="newPage.description"
              class="textarea"
              name="description"
            />
            <p v-show="errors.has('description')" class="help is-danger">
              Εισάγετε περιγραφή
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Περιεχόμενο *</label>
          <div class="control">
            <editor :content="newPage.content" @changeContent="changeContent" />
            <p v-show="errors.has('content')" class="help is-danger">
              Εισάγετε περιεχόμενο
            </p>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import request from '@/utils/request';
import AddItem from '@/views/components/AddItem';
import Editor from '@/views/components/Editor';

export default {
  components: {
    Editor,
  },
  extends: AddItem,
  data() {
    return {
      newPage: {
        title: '',
        description: '',
        slug: '',
        content: '',
      },
      isSaving: false,
    };
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/pages', this.newPage);
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router
          .push({ name: 'pages.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η σελίδα αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newPage = {
        title: '',
        description: '',
        slug: '',
        content: '',
      };

      this.$validator.reset();
    },
    changeContent(content) {
      this.newPage.content = content;
    },
  },
};
</script>
