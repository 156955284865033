<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <div class="columns is-multiline">
      <div class="column is-full">
        <collection-info :collection="model" />
      </div>
    </div>

    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Μάρκα
                  <router-link
                    :to="{
                      name: 'products.brands.edit',
                      params: { uuid: $route.params.uuid },
                    }"
                    class="tag"
                  >
                    {{ brand.title }}
                  </router-link>
                  Επεξεργασία Σειράς
                  <span class="tag">
                    <strong>{{ model.title }}</strong>
                  </span>
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field">
                  <div class="control">
                    <router-link
                      :to="{
                        name: 'products.brands.collections.list',
                        params: { uuid: $route.params.uuid },
                      }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <EditGeneralDetails
                :general="general"
                @updateGeneral="updateGeneral"
              />
            </div>
          </div>

          <edit-photos
            :model="model"
            :photos="photos"
            :is-loading="isLoadingPhotos"
            @updatePhotos="updatePhotos"
          />

          <ToggleableCard title="Meta">
            <div class="card-content">
              <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
            </div>
          </ToggleableCard>

          <ToggleableCard title="Social Meta">
            <div class="card-content">
              <EditSocialMetaDetails
                :social-meta="socialMeta"
                @updateSocialMeta="updateSocialMeta"
              />
            </div>
          </ToggleableCard>

          <div class="card">
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column">
        <ExistingCollections :items="items" :brand="model.brand" />

        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              Dynamic Variables
            </div>
          </div>
          <div class="card-content">
            <strong>Available Variables</strong>
            <br />
            <ul>
              <li>[collection_name]</li>
              <li>[brand_name]</li>
              <li>[discount_percentage]</li>
              <li>[count_of_products]</li>
            </ul>

            <br />

            <strong>H1 (tag) (sample)</strong>
            <br />
            <p>
              [brand_name] [collection_name]
            </p>

            <br />

            <strong>Περιεχόμενο (p tag) (sample)</strong>
            <br />
            <p>
              Ανακάλυψε τα προϊόντα [brand_name] [collection_name] και απόκτησε
              αυτά που καλύπτουν ιδανικά τις ανάγκες σου, σε μοναδικές προσφορές
              έως -[discount_percentage]%. Άμεση παράδοση, μοναδική εξυπηρέτηση
              & τεράστια ποικιλία στις καλύτερες τιμές μόνο στο Carespot, το
              online φαρμακείο που σε φροντίζει!
            </p>

            <br />

            <strong>Meta Title (sample)</strong>
            <br />
            <p>
              [brand_name] [collection_name] Έως -[discount_percentage]%
            </p>

            <br />

            <strong>Meta Description (sample)</strong>
            <br />
            <p>
              Βρες τα προϊόντα [brand_name] [collection_name] σε προσφορές έως
              -[discount_percentage]%! Άμεση παράδοση μόνο στο Carespot, το
              online φαρμακείο που σε φροντίζει.
            </p>
          </div>
        </div>
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';
import EditPhotos from '@/views/components/EditPhotos';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingCollections from '../../components/ExistingCollections';
import CollectionInfo from './components/CollectionInfo';

export default {
  components: {
    EditMetaDetails,
    EditSocialMetaDetails,
    EditGeneralDetails,
    EditPhotos,
    ToggleableCard,
    LoadingContainer,
    ExistingCollections,
    CollectionInfo,
  },
  extends: AddItem,
  data() {
    return {
      meta: {},
      socialMeta: {},
      general: {},
      photos: [],
      isLoadingPhotos: false,
    };
  },
  computed: {
    ...mapGetters({
      brand: 'brands/edit/getBrand',
      model: 'collections/edit/getCollection',
      items: 'collections/all/getCollections',
      isLoadingModel: 'collections/edit/getIsLoading',
      isLoadingItems: 'collections/all/getIsLoading',
    }),
  },
  watch: {
    $route: 'fetchData',
    model: {
      handler(newVal, oldVal) {
        // Update info only when changing model
        if (newVal.id !== oldVal.id) {
          this.general = _pick(newVal, [
            'title',
            'slug',
            'description',
            'content',
            'active',
          ]);

          this.meta = _clone(this.model.meta);
          this.socialMeta = _clone(this.model.social_meta);
        }

        // Update photos always since this functions independently
        this.photos = _clone(this.model.photos);
      },
      deep: true,
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getBrand: 'brands/edit/getBrand',
      getCollection: 'collections/edit/getCollection',
      getCollectionPhotos: 'collections/edit/getCollectionPhotos',
      updateCollection: 'collections/edit/updateCollection',
      getCollections: 'collections/all/getCollections',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getBrand({ uuid: this.$route.params.uuid }),
          this.getCollection({ uuid: this.$route.params.collection }),
          this.getCollections({ uuid: this.$route.params.uuid }),
        ]);
      } catch (err) {
        this.$router.push('/error').catch(e => console.log(e));
      }
    },
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateCollection({
          uuid: this.model.uuid,
          collection: {
            ...this.general,
            brand_id: this.brand.id,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η σειρά αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = _pick(this.model, [
        'title',
        'slug',
        'description',
        'content',
        'active',
      ]);

      this.meta = _clone(this.model.meta);
      this.socialMeta = _clone(this.model.social_meta);
      this.photos = _clone(this.model.photos);

      this.$children.forEach(child => child.$validator.reset());
    },
    updateMeta(meta) {
      this.meta = meta;
    },
    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },
    updateGeneral(general) {
      this.general = general;
    },
    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;
        await this.getCollectionPhotos({ uuid: this.$route.params.collection });
        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },
  },
};
</script>
