<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Πληροφορίες Box Now</div>
    </div>
    <div class="card-content">
      <table class="table is-fullwidth">
        <tbody>
          <tr v-if="order.box_now.boxnowReferenceNumber">
            <td>
              <span>
                Reference Number:
              </span>
              <small class="tag">{{
                order.box_now.boxnowReferenceNumber
              }}</small>
            </td>
          </tr>
          <tr v-if="order.box_now.boxnowLockerId">
            <td>
              <span>
                Locker ID:
              </span>
              <small class="tag">{{ order.box_now.boxnowLockerId }}</small>
            </td>
          </tr>
          <tr v-if="order.box_now.boxnowLockerName">
            <td>
              <span>
                Ονομασία Locker:
              </span>
              <small class="tag">{{ order.box_now.boxnowLockerName }}</small>
            </td>
          </tr>
          <tr v-if="order.box_now.boxnowLockerAddressLine1">
            <td>
              <span>
                Δ/νση Locker:
              </span>
              <small class="tag">
                {{ order.box_now.boxnowLockerAddressLine1 }}, ΤΚ:
                {{ order.box_now.boxnowLockerPostalCode }}
              </small>
            </td>
          </tr>
          <tr v-if="hasBoxnowParcelIds">
            <td>
              <div>
                Parcel IDs:
              </div>
              <small
                v-for="(item, index) in boxnowParcelIds"
                :key="index"
                class="tag"
              >
                {{ item }}
              </small>
            </td>
          </tr>
          <tr v-if="hasBoxnowVoucherLinks">
            <td>
              <div>
                Voucher Links:
              </div>
              <small
                v-for="(item, index) in boxnowVoucherLinks"
                :key="index"
                class="tag"
              >
                <a :href="item" download="sample.PDF" target="blank">
                  Voucher {{ index + 1 }}
                </a>
              </small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoxnowInfo',

  props: {
    order: Object,
  },

  computed: {
    boxnowParcelIds() {
      return this.order.box_now && this.order.box_now.boxnowParcelIds
        ? this.order.box_now.boxnowParcelIds
        : [];
    },

    hasBoxnowParcelIds() {
      return this.boxnowParcelIds.length > 0;
    },

    boxnowVoucherLinks() {
      return this.order.box_now && this.order.box_now.boxnowVoucherLinks
        ? this.order.box_now.boxnowVoucherLinks
        : [];
    },

    hasBoxnowVoucherLinks() {
      return this.boxnowVoucherLinks.length > 0;
    },
  },
};
</script>

<style scoped>
::v-deep .tag {
  margin-right: 0.5rem;
}
</style>
