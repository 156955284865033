<template>
  <form @submit.prevent="submit">
    <ToggleableCard :is-open="true" title="Bronze">
      <div class="card-content">
        <div class="columns is-multiline is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Από</label>
              <div class="control">
                <input value="0" class="input" type="number" disabled />
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field is-half">
              <label class="label">Εώς *</label>
              <div class="control">
                <input
                  v-validate="`required|max_value:${max}`"
                  v-model.number="form.limits.low"
                  :min="1000"
                  :step="1000"
                  class="input"
                  type="number"
                  name="form.limits.low"
                />
                <p
                  v-if="errors.has('form.limits.low:decimal')"
                  class="help is-danger"
                >
                  Εισάγετε αριθμό πόντων
                </p>
                <p
                  v-if="errors.has('form.limits.low:max_value')"
                  class="help is-danger"
                >
                  Εισάγετε αριθμό μικρότερο από τον μέγιστο αριθμό πόντων Silver
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Ποσοστό Έκπτωσης *</label>
              <div class="control">
                <input
                  v-model.number="form.categories.bronze.discount"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ToggleableCard>

    <ToggleableCard :is-open="true" title="Silver">
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Από *</label>
              <div class="control">
                <input
                  v-validate="`required|max_value:${max}`"
                  v-model.number="form.limits.low"
                  :min="1000"
                  :step="1000"
                  class="input"
                  type="number"
                  name="form.limits.low"
                />
                <p
                  v-if="errors.has('form.limits.low:decimal')"
                  class="help is-danger"
                >
                  Εισάγετε αριθμό πόντων
                </p>
                <p
                  v-if="errors.has('form.limits.low:max_value')"
                  class="help is-danger"
                >
                  Εισάγετε αριθμό μικρότερο από τον μέγιστο αριθμό πόντων Silver
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Εώς *</label>
              <div class="control">
                <input
                  v-validate="`required|min_value:${min}`"
                  v-model.number="form.limits.top"
                  :min="1000"
                  :step="1000"
                  class="input"
                  type="number"
                  name="form.limits.top"
                />
              </div>
              <p
                v-if="errors.has('form.limits.top:decimal')"
                class="help is-danger"
              >
                Εισάγετε αριθμό πόντων
              </p>
              <p
                v-if="errors.has('form.limits.top:min_value')"
                class="help is-danger"
              >
                Εισάγετε αριθμό μεγαλύτερο από τον ελάχιστο αριθμό πόντων Silver
              </p>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Ποσοστό Έκπτωσης *</label>
              <div class="control">
                <input
                  v-model.number="form.categories.silver.discount"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ToggleableCard>

    <ToggleableCard :is-open="true" title="Gold">
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Από *</label>
              <div class="control">
                <input
                  v-validate="`required|min_value:${min}`"
                  v-model.number="form.limits.top"
                  class="input"
                  type="number"
                  min="1000"
                  step="1000"
                  name="form.limits.top"
                />
                <p
                  v-if="errors.has('form.limits.top:decimal')"
                  class="help is-danger"
                >
                  Εισάγετε αριθμό πόντων
                </p>
                <p
                  v-if="errors.has('form.limits.top:min_value')"
                  class="help is-danger"
                >
                  Εισάγετε αριθμό μεγαλύτερο από τον ελάχιστο αριθμό πόντων
                  Silver
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Ποσοστό Έκπτωσης *</label>
              <div class="control">
                <input
                  v-model.number="form.categories.gold.discount"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ToggleableCard>

    <div class="card">
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import FormSubmit from '@/views/components/FormSubmit';
import ToggleableCard from '@/views/components/ToggleableCard';
import Notification from '@/views/components/Notification';

export default {
  components: {
    Notification,
    FormSubmit,
    ToggleableCard,
  },
  data: () => ({
    form: {},
    isSaving: false,
  }),
  computed: {
    ...mapGetters({
      loyalty: 'loyalty/getLoyalty',
      categories: 'loyalty/getCategories',
    }),
    min() {
      const low = get('low', this.form, 'limits.low', 0);
      // eslint-disable-next-line
      return !!low ? this.categories.limits.low + 1000 : 0;
    },
    max() {
      const top = get('top', this.form, 'limits.top', 0);
      // eslint-disable-next-line
      return !!top ? this.categories.limits.top - 1000 : 0;
    },
  },
  watch: {
    categories: {
      handler(newVal) {
        this.form = this.createData(newVal);
      },
      immediate: true,
    },
  },
  async created() {
    try {
      await this.getLoyalty();
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getLoyalty: 'loyalty/getLoyalty',
      updateLoyalty: 'loyalty/updateLoyalty',
    }),
    createData(categories) {
      return cloneDeep(categories);
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.updateLoyalty({
          loyalty: {
            ...this.loyalty,
            categories: this.form.categories,
            limits: this.form.limits,
          },
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Οι τιμές αποθηκεύτηκαν',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isSaving = false;
      }
    },
    reset() {
      this.form = this.createData(this.categories);

      this.$validator.reset();
    },
  },
};
</script>
