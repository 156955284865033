<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          ΦΠΑ
        </div>
      </div>
      <div class="card-header-icon">
        <div class="field has-addons is-marginless">
          <div class="control">
            <router-link :to="{ name: 'settings.taxes.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Ονομασία *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="newTax.title"
              class="input"
              type="text"
              name="title"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε ονομασία
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Ποσοστό *</label>
          <div class="control">
            <input
              v-validate="'required|min_value:0.1'"
              v-model.number="newTax.percentage"
              class="input"
              type="number"
              min="0"
              step=".1"
              name="percentage"
            />
            <p v-show="errors.has('percentage')" class="help is-danger">
              Εισάγετε ποσοστό
            </p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EditItem from '@/views/components/EditItem';

export default {
  extends: EditItem,
  data() {
    return {
      newTax: {
        title: '',
        percentage: 0,
      },
    };
  },
  methods: {
    ...mapActions({
      addTax: 'taxes/add/addTax',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addTax(this.newTax);
        this.isSaving = false;

        this.$router
          .push({ name: 'settings.taxes.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η κατηγορία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newTax = {
        title: '',
        percentage: 0,
      };

      this.$validator.reset();
    },
  },
};
</script>
