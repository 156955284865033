<template>
  <form @submit.prevent="submit">
    <ToggleableCard :is-open="true" title="Διάρκεια ζωής πόντων">
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Μέρες *</label>
              <div class="control">
                <input
                  v-model.number="form.expire_at"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                  name="form.expire_at"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ToggleableCard>

    <ToggleableCard :is-open="true" title="Πόντοι">
      <div class="card-content">
        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Αντιστοιχία 1 ευρώ *</label>
              <div class="control">
                <input
                  v-model.number="form.points.order"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                  name="form.points.order"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Εγγραφή στο Carespot *</label>
              <div class="control">
                <input
                  v-model.number="form.points.register"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                  name="form.points.register"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Εγγραφή Newsletter *</label>
              <div class="control">
                <input
                  v-model.number="form.points.newsletter"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                  name="form.points.newsletter"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Συμπλήρωση Προφίλ *</label>
              <div class="control">
                <input
                  v-model.number="form.points.profile"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                  name="form.points.profile"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Σχολιασμός προιόντος *</label>
              <div class="control">
                <input
                  v-model.number="form.points.product"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                  name="form.points.product"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Σχολιασμός άρθρου *</label>
              <div class="control">
                <input
                  v-model.number="form.points.post"
                  :min="0"
                  :step="1"
                  class="input"
                  type="number"
                  name="form.points.post"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ToggleableCard>

    <div class="card">
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import FormSubmit from '@/views/components/FormSubmit';
import ToggleableCard from '@/views/components/ToggleableCard';
import Notification from '@/views/components/Notification';

export default {
  components: {
    Notification,
    FormSubmit,
    ToggleableCard,
  },
  data: () => ({
    form: {},
    isSaving: false,
  }),
  computed: {
    ...mapGetters({
      loyalty: 'loyalty/getLoyalty',
      settings: 'loyalty/getSettings',
    }),
  },
  watch: {
    settings: {
      handler(newVal) {
        this.form = this.createData(newVal);
      },
      immediate: true,
    },
  },
  async created() {
    try {
      await this.getLoyalty();
    } catch (err) {
      console.log(err);
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getLoyalty: 'loyalty/getLoyalty',
      updateLoyalty: 'loyalty/updateLoyalty',
    }),
    createData(settings) {
      return cloneDeep(settings);
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.updateLoyalty({
          loyalty: {
            ...this.loyalty,
            settings: this.form,
          },
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Οι τιμές αποθηκεύτηκαν',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isSaving = false;
      }
    },
    reset() {
      this.form = this.createData(this.settings);

      this.$validator.reset();
    },
  },
};
</script>
