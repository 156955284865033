<template>
  <LoadingContainer :is-loading="isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div
                  v-if="this.$route.query.parent_id"
                  class="title is-6 is-marginless"
                >
                  {{ parent.type | capitalize }} Κατηγορία
                  <router-link
                    :to="{
                      name: 'products.categories.edit',
                      params: { uuid: parent.uuid },
                    }"
                    class="tag"
                  >
                    {{ parent.title }}
                  </router-link>
                  Προσθήκη Υποκατηγορίας
                </div>
                <div v-else class="title is-6 is-marginless">
                  Προσθήκη Top Κατηγορίας
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.categories.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <AddGeneralDetails
                :general="general"
                :show-title-en="true"
                @updateGeneral="updateGeneral"
              />
            </div>
          </div>

          <div class="card">
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column is-one-quarter">
        <ExistingCategories
          :categories="categories"
          :categories-per-type="categoriesPerType"
        />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddItem from '@/views/components/AddItem';
import AddGeneralDetails from '@/views/components/AddGeneralDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingCategories from '../../components/ExistingCategories';

export default {
  components: {
    AddGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingCategories,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        title: '',
        title_en: '',
        slug: '',
        description: '',
        content: '',
        active: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      parent: 'categories/edit/getCategory',
      categories: 'categories/list/getCategoriesTree',
      categoriesPerType: 'categories/list/getCategoriesPerType',
      isLoadingItems: 'categories/list/getIsLoading',
    }),
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      getCategory: 'categories/edit/getCategory',
      getCategories: 'categories/list/getCategories',
      addCategory: 'categories/add/addCategory',
    }),
    async fetchData() {
      try {
        const uuid = this.$route.query.parent_id;

        if (uuid) {
          await Promise.all([this.getCategory({ uuid }), this.getCategories()]);
        } else {
          await this.getCategories();
        }
      } catch (err) {
        this.$router.push('/error').catch(e => console.log(e));
      }
    },
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        const uuid = this.$route.query.parent_id || null;

        this.isSaving = true;
        await this.addCategory({
          ...this.general,
          parent_id: uuid,
          type: uuid ? this.getType(this.parent) : 'top',
        });
        this.isSaving = false;

        this.$router
          .push({ name: 'products.categories.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η κατηγορία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    getType({ type }) {
      if (type === 'top') {
        return 'mid';
      }

      if (type === 'mid') {
        return 'low';
      }

      return 'top';
    },
    reset() {
      this.general = {
        title: '',
        title_en: '',
        slug: '',
        content: '',
        active: false,
      };

      this.$children.forEach(child => child.$validator.reset());
    },
    updateGeneral(general) {
      this.general = general;
    },
  },
};
</script>
