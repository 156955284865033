<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form class="has-sticky-submit" novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Επεξεργασία {{ model.type | capitalize }} Κατηγορίας -
                  (Products page)
                  <p>
                    Μόνο για την σελίδα των προϊόντων (e.g. carespot.gr/c/{{
                      model.slug
                    }}/products)
                  </p>
                  <span class="tag">
                    <strong>{{ model.title }}</strong>
                  </span>
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <div class="control">
                    <RouterLink
                      :to="{ name: 'products.categories.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </RouterLink>
                  </div>
                  <div v-if="model.type !== 'low'" class="control">
                    <RouterLink
                      :to="{
                        name: 'products.categories.add',
                        params: { uuid: $route.params.uuid },
                      }"
                      class="button"
                    >
                      <span class="icon is-small"><i class="fa fa-plus"/></span
                      ><span>Υποκατηγορία</span>
                    </RouterLink>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <CategoriesEditMenu
                v-if="model.type === 'top' || model.type === 'mid'"
              />
              <EditGeneralDetailsCategoryProductsPage
                :general="general"
                :orders="orders"
                :show-title-en="true"
                @updateGeneral="updateGeneral"
              />
            </div>
          </div>

          <ToggleableCard title="Meta">
            <div class="card-content">
              <EditMetaDetailsCategoryProductsPage
                :meta="meta"
                @updateMeta="updateMeta"
              />
            </div>
          </ToggleableCard>

          <ToggleableCard title="Social Meta">
            <div class="card-content">
              <EditSocialMetaDetailsCategoryProductsPage
                :social-meta="socialMeta"
                :is-read-only="true"
                @updateSocialMeta="updateSocialMeta"
              />
            </div>
          </ToggleableCard>

          <StickyFormFooter>
            <template v-slot:left>
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </template>
            <template v-slot:right>
              {{ model.title }}
            </template>
          </StickyFormFooter>
        </form>
      </div>
      <div class="column is-one-quarter">
        <ExistingCategories
          :categories="categories"
          :categories-per-type="categoriesPerType"
        />

        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              Dynamic Variables for {{ model.type }}-level category
            </div>
          </div>

          <!-- BEGIN: Show dynamic variables usage for the TOP-LEVEL CATEGORY -->
          <div v-if="model.type === 'top'" class="card-content">
            <strong>Available Variables</strong>
            <br />
            <ul>
              <li>[category_name]</li>
              <li>[discount_percentage]</li>
              <li>[count_of_products]</li>
            </ul>

            <br />

            <strong>H1 (tag)</strong>
            <br />
            <p>
              Προϊόντα [category_name]
            </p>

            <br />

            <strong>Περιεχόμενο (p tag)</strong>
            <br />
            <p>
              Βρες ανάµεσα σε [count_of_products] προϊόντα από την κατηγορία
              [category_name] αυτό που σου ταιριάζει στις καλύτερες τιμές! Κάνε
              τις αγορές σου εύκολα & γρήγορα κερδίζοντας πόντους σε κάθε σου
              παραγγελία με το πρόγραμμα care-δίζω και επωφελήσου από τα
              μοναδικά προνόμια.
            </p>

            <br />

            <strong>Meta Title</strong>
            <br />
            <p>
              [category_name] - Προϊόντα σε προσφορές έως
              -[discount_percentage]%
            </p>

            <br />

            <strong>Meta Description</strong>
            <br />
            <p>
              Διάλεξε ανάμεσα σε [count_of_products] προϊόντα της κατηγορίας
              [category_name] με εκπτώσεις έως -[discount_percentage]%. Άμεση
              παράδοση και μοναδική εξυπηρέτηση!
            </p>
          </div>
          <!-- END: Show dynamic variables usage for the TOP-LEVEL CATEGORY -->

          <!-- BEGIN: Show dynamic variables usage for the MID-LEVEL CATEGORY -->
          <div v-if="model.type === 'mid'" class="card-content">
            <strong>Available Variables</strong>
            <br />
            <ul>
              <li>[category_name]</li>
              <li>[discount_percentage]</li>
              <li>[count_of_products]</li>
            </ul>

            <br />

            <strong>H1 (tag)</strong>
            <br />
            <p>
              Προϊόντα [category_name]
            </p>

            <br />

            <strong>Περιεχόμενο (p tag)</strong>
            <br />
            <p>
              Βρες ανάµεσα σε [count_of_products] προϊόντα από την κατηγορία
              [category_name] - [top_level_category_name] αυτό που σου ταιριάζει
              στις καλύτερες τιμές! Κάνε τις αγορές σου εύκολα & γρήγορα
              κερδίζοντας πόντους σε κάθε σου παραγγελία με το πρόγραμμα
              care-δίζω και επωφελήσου από τα μοναδικά προνόμια.
            </p>

            <br />

            <strong>Meta Title</strong>
            <br />
            <p>
              [category_name] - Προϊόντα έως -[discount_percentage]%
            </p>

            <br />

            <strong>Meta Description</strong>
            <br />
            <p>
              Διάλεξε ανάμεσα σε [count_of_products] προϊόντα της κατηγορίας
              [category_name] με εκπτώσεις έως -[discount_percentage]%. Άμεση
              παράδοση και μοναδική εξυπηρέτηση!
            </p>
          </div>
          <!-- END: Show dynamic variables usage for the MID-LEVEL CATEGORY -->
        </div>
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import slug from 'slug';
import { clone, pick, isEmpty } from 'lodash';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditGeneralDetailsCategoryProductsPage from '@/views/components/EditGeneralDetailsCategoryProductsPage';
import EditRedirectionDetails from '@/views/components/EditRedirectionDetails';
import EditMetaDetailsCategoryProductsPage from '@/views/components/EditMetaDetailsCategoryProductsPage';
import EditSocialMetaDetailsCategoryProductsPage from '@/views/components/EditSocialMetaDetailsCategoryProductsPage';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import ExistingCategories from '../../components/ExistingCategories';
import CategoriesEditMenu from '../../components/CategoriesEditMenu';

export default {
  components: {
    EditRedirectionDetails,
    EditMetaDetailsCategoryProductsPage,
    EditSocialMetaDetailsCategoryProductsPage,
    EditGeneralDetails,
    EditGeneralDetailsCategoryProductsPage,
    ToggleableCard,
    LoadingContainer,
    ExistingCategories,
    StickyFormFooter,
    CategoriesEditMenu,
  },

  extends: AddItem,

  data() {
    return {
      redirection: {},
      meta: {},
      socialMeta: {},
      general: {},
      oldOrder: 0,
    };
  },

  computed: {
    ...mapGetters({
      model: 'categories/edit/getCategory',
      orders: 'categories/edit/getOrders',
      categories: 'categories/list/getCategoriesTree',
      categoriesPerType: 'categories/list/getCategoriesPerType',
      isLoadingModel: 'categories/edit/getIsLoading',
      isLoadingItems: 'categories/list/getIsLoading',
    }),

    hasChangedTitle() {
      return this.model.title !== this.general.title;
    },

    hasModelSlug() {
      return this.model.slug;
    },
  },

  watch: {
    $route: 'fetchData',

    model: {
      handler(newVal) {
        this.general = pick(newVal, [
          'title',
          'title_en',
          'slug',
          'description',
          'content',
          'description_for_category_products_page',
          'content_for_category_products_page',
          'active',
          'order',
        ]);

        this.oldOrder = this.general.order;

        this.redirection = clone(
          newVal.redirection || {
            new: null,
            old: null,
          },
        );
        this.meta = clone(newVal.meta);
        this.socialMeta = clone(newVal.social_meta);
      },
      deep: true,
    },

    'general.title': {
      handler(newVal, oldVal) {
        if (!isEmpty(this.model) && newVal !== oldVal) {
          if (
            (!this.hasChangedTitle && !this.hasModelSlug) ||
            this.hasChangedTitle
          ) {
            const newSlug = slug(newVal.toLowerCase());
            this.general.slug = newSlug;

            const titleRegExp = new RegExp(this.model.title, 'g');
            const slugRegExp = new RegExp(this.model.slug, 'g');

            const newMeta = {
              ...this.model.meta,
              title: this.model.meta.title_for_category_products_page.replace(
                titleRegExp,
                newVal,
              ),
              description: this.model.meta.description_for_category_products_page.replace(
                titleRegExp,
                newVal,
              ),
              keywords: this.model.meta.keywords.replace(titleRegExp, newVal),
              schema: this.model.meta.schema.replace(slugRegExp, newSlug),
            };

            this.updateMeta(newMeta);

            const newSocialMeta = {
              ...this.model.social_meta,
              og_title: this.model.social_meta.og_title.replace(
                titleRegExp,
                newVal,
              ),
              og_description: this.model.social_meta.og_description.replace(
                titleRegExp,
                newVal,
              ),
              og_url: this.redirection.new,
              twitter_title: this.model.social_meta.twitter_title.replace(
                titleRegExp,
                newVal,
              ),
              twitter_description: this.model.social_meta.twitter_description.replace(
                titleRegExp,
                newVal,
              ),
            };

            this.updateSocialMeta(newSocialMeta);
          }

          if (!this.hasChangedTitle && this.hasModelSlug) {
            const values = pick(this.model, [
              'title_en',
              'slug',
              'description',
              'content',
              'description_for_category_products_page',
              'content_for_category_products_page',
              'active',
              'order',
            ]);

            this.general = {
              ...this.general,
              ...values,
            };

            this.oldOrder = this.general.order;

            this.redirection = clone(
              this.model.redirection || {
                new: null,
                old: null,
              },
            );
            this.meta = clone(this.model.meta);
            this.socialMeta = clone(this.model.social_meta);
          }
        }
      },
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    ...mapActions({
      getCategory: 'categories/edit/getCategory',
      updateCategory: 'categories/edit/updateCategory',
      getCategories: 'categories/list/getCategories',
    }),

    async fetchData() {
      try {
        await Promise.all([
          this.getCategory({ uuid: this.$route.params.uuid }),
          this.getCategories(),
        ]);
      } catch (err) {
        this.$router.push('/error').catch(e => console.log(e));
      }
    },

    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        const order = {
          old: this.oldOrder,
          new: this.general.order,
        };

        await this.updateCategory({
          uuid: this.model.uuid,
          category: {
            ...this.general,
            order,
            type: this.model.type,
            parent_id: this.model.parent_id,
            redirection: this.redirection,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η κατηγορία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.general = pick(this.model, [
        'title',
        'title_en',
        'slug',
        'description',
        'content',
        'description_for_category_products_page',
        'content_for_category_products_page',
        'active',
        'order',
      ]);

      this.oldOrder = this.general.order;

      this.redirection = clone(
        this.model.redirection || {
          new: null,
          old: null,
        },
      );
      this.meta = clone(this.model.meta);
      this.socialMeta = clone(this.model.social_meta);

      this.$children.forEach(child => child.$validator.reset());
    },

    updateMeta(meta) {
      this.meta = meta;
    },

    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },

    updateGeneral(general) {
      this.general = general;
    },
  },
};
</script>

<style scoped>
.title > p {
  line-height: 26px;
}
</style>
