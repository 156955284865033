<template>
  <form @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Videos <span class="tag">Προσθήκη</span>
          </div>
        </div>
        <div class="card-header-icon">
          <router-link :to="{ name: 'content.videos.list' }" class="button">
            <span class="icon is-small"><i class="fa fa-list"/></span
            ><span>Λίστα</span>
          </router-link>
        </div>
      </div>
      <div class="card-content">
        <div class="field">
          <label class="label">Ονομασία *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="general.title"
              class="input"
              type="text"
              name="title"
            />
          </div>
          <p v-show="errors.has('title')" class="help is-danger">
            Εισάγετε ονομασία
          </p>
        </div>

        <div class="field">
          <label class="label">Περιγραφή *</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model.trim="general.description"
              class="textarea"
              name="description"
            />
          </div>
          <p v-show="errors.has('description')" class="help is-danger">
            Εισάγετε περιγραφή
          </p>
        </div>

        <div class="field">
          <label class="label">YouTube ID *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="general.youtube_id"
              class="input"
              type="text"
              name="youtube_id"
            />
          </div>
          <p v-show="errors.has('title')" class="help is-danger">
            Εισάγετε youtube id
          </p>
        </div>
      </div>
    </div>

    <ToggleableCard :is-open="true" title="Συμπτώματα - Tags">
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-full">
            <label class="label">Συμπτώματα</label>
            <symptoms-search
              :symptoms="general.symptoms"
              @selectSymptom="selectSymptom"
              @removeSymptom="removeSymptom"
            />
          </div>

          <div class="column is-full">
            <div class="field">
              <label class="label">Tags</label>
              <div class="control">
                <multiselect
                  v-model="general.tags"
                  :options="tags"
                  :multiple="true"
                  name="tags"
                  class="multiple"
                  track-by="id"
                  label="title"
                  placeholder="Επιλέξτε tags"
                  selected-label="Επιλεγμένο"
                  select-label="Πατήστε enter για επιλογή"
                  deselect-label="Πατήστε enter για απο-επιλογή"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ToggleableCard>

    <div class="card">
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import AddItem from '@/views/components/AddItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import SymptomsSearch from '../components/SymptomsSearch';

export default {
  components: {
    Multiselect,
    ToggleableCard,
    SymptomsSearch,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        title: '',
        description: '',
        youtube_id: '',
        tags: [],
        symptoms: [],
      },
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      tags: 'tags/all/getTags',
    }),
  },
  async created() {
    try {
      await this.getTags();
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getTags: 'tags/all/getTags',
      addVideo: 'videos/add/addVideo',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addVideo({
          ...this.general,
          tags: this.general.tags.map(({ id }) => id),
          symptoms: this.general.symptoms.map(({ id }) => id),
        });
        this.isSaving = false;

        this.$router
          .push({ name: 'content.videos.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το video αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = {
        title: '',
        description: '',
        youtube_id: '',
        tags: [],
        symptoms: [],
      };

      this.$validator.reset();
    },
    selectSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: [...this.general.symptoms, symptom],
      };
    },
    removeSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: this.general.symptoms.filter(({ id }) => id !== symptom.id),
      };
    },
  },
};
</script>
