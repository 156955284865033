<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <form class="has-sticky-submit" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Tags <span class="tag">{{ model.title }}</span>
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link :to="{ name: 'content.tags.list' }" class="button">
                  <span class="icon is-small"><i class="fa fa-list"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
              <div class="control">
                <button type="button" class="button" @click="askToDeleteItem">
                  <span class="icon is-small"><i class="fa fa-trash"/></span
                  ><span>Διαγραφή</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content">
          <EditGeneralDetails
            :general="general"
            :can-activate="true"
            @updateGeneral="updateGeneral"
          />

          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Δημοφιλές *</label>
                <div class="control">
                  <ToggleButton
                    :sync="true"
                    :value="general.popular"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="handleChangePopular"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <edit-photos
        :model="model"
        :photos="photos"
        :is-loading="isLoadingPhotos"
        @updatePhotos="updatePhotos"
      />

      <ToggleableCard title="Meta">
        <div class="card-content">
          <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
        </div>
      </ToggleableCard>

      <ToggleableCard title="Social Meta">
        <div class="card-content">
          <EditSocialMetaDetails
            :social-meta="socialMeta"
            @updateSocialMeta="updateSocialMeta"
          />
        </div>
      </ToggleableCard>

      <StickyFormFooter>
        <template v-slot:left>
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </template>
        <template v-slot:right>
          <a
            v-tooltip="'Προβολή στο e-shop'"
            :href="model.path"
            target="_blank"
          >
            {{ model.title }} <i class="fa fa-arrow-right" />
          </a>
        </template>
      </StickyFormFooter>
    </form>

    <confirmation
      :model-name="tag.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του tag;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import request from '@/utils/request';
import LoadingContainer from '@/views/components/LoadingContainer';
import EditItem from '@/views/components/EditItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';
import EditPhotos from '@/views/components/EditPhotos';
import ToggleableCard from '@/views/components/ToggleableCard';
import StickyFormFooter from '@/views/components/StickyFormFooter';

export default {
  components: {
    EditGeneralDetails,
    EditMetaDetails,
    EditSocialMetaDetails,
    EditPhotos,
    ToggleableCard,
    StickyFormFooter,
    LoadingContainer,
  },
  extends: EditItem,
  data() {
    return {
      tag: {},
      general: {},
      meta: {},
      socialMeta: {},
      photos: [],
      isLoadingPhotos: false,
    };
  },
  computed: {
    ...mapGetters({
      model: 'tags/edit/getTag',
      items: 'tags/all/getTags',
      isLoadingModel: 'tags/edit/getIsLoading',
      isLoadingItems: 'tags/list/getIsLoading',
    }),
  },
  watch: {
    $route: 'fetchData',
    model: {
      handler(newVal) {
        this.general = _pick(newVal, [
          'title',
          'slug',
          'description',
          'content',
          'active',
          'popular',
        ]);

        this.meta = _clone(this.model.meta);
        this.socialMeta = _clone(this.model.social_meta);
        this.photos = _clone(this.model.photos);
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      getTagPhotos: 'tags/edit/getTagPhotos',
      getTag: 'tags/edit/getTag',
      updateTag: 'tags/edit/updateTag',
      getTags: 'tags/all/getTags',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getTag({ uuid: this.$route.params.uuid }),
          this.getTags(),
        ]);
      } catch (err) {
        this.$router.push('/error').catch(e => console.log(e));
      }
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateTag({
          uuid: this.model.uuid,
          tag: {
            ...this.general,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το tag αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = _pick(this.model, [
        'title',
        'slug',
        'description',
        'content',
        'active',
        'popular',
      ]);
      this.meta = _clone(this.model.meta);
      this.socialMeta = _clone(this.model.social_meta);
      this.photos = _clone(this.model.photos);

      this.$validator.reset();
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/tags/${this.tag.uuid}`);
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το tag διαγράφτηκε',
          });
          this.$router
            .push({ name: 'content.tags.list' })
            .catch(err => console.log(err));
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    updateMeta(meta) {
      this.meta = meta;
    },
    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },
    updateGeneral(general) {
      this.general = general;
    },
    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;
        await this.getTagPhotos({ uuid: this.$route.params.uuid });
        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },

    handleChangePopular(e) {
      this.general = {
        ...this.general,
        popular: e.value,
      };
    },
  },
};
</script>
