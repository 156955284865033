<template>
  <div class="columns">
    <div class="column is-three-quarters">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Email Επικοινωνίας
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link
                  :to="{ name: 'settings.emailTemplates.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
              <div class="control">
                <button
                  :disabled="emailTemplate.default === 1"
                  class="button"
                  @click="askToDeleteItem"
                >
                  <span class="icon is-small"><i class="fa fa-trash"/></span
                  ><span>Διαγραφή</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <form @submit.prevent="submit">
          <div class="card-content">
            <div class="field">
              <label class="label">Τίτλος *</label>
              <div class="control">
                <textarea
                  v-validate="'required'"
                  v-model.trim="newEmailTemplate.title"
                  class="textarea"
                  name="title"
                  rows="2"
                />
              </div>
              <div v-show="errors.has('title')" class="help is-danger">
                Εισάγετε τίτλο
              </div>
            </div>

            <div class="field">
              <label class="label">Περιεχόμενο *</label>
              <div class="control">
                <textarea
                  v-validate="'required'"
                  v-model.trim="newEmailTemplate.content"
                  class="textarea"
                  name="content"
                  rows="5"
                />
              </div>
              <div v-show="errors.has('content')" class="help is-danger">
                Εισάγετε περιεχόμενο
              </div>
            </div>
          </div>
          <div class="card-footer">
            <FormSubmit :is-saving="isSaving" @reset="reset" />
          </div>
        </form>

        <confirmation
          :model-name="emailTemplate.title"
          :is-open="isOpen"
          description="Είστε βέβαιος για τη διαγραφή αυτού του email;"
          @closeModal="closeModal"
        />
      </div>
    </div>
    <div class="column is-one-quarter">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Οδηγίες</div>
        </div>
        <div class="card-content">
          <p>
            Σε περιπτωση που χρειάζεστε δυναμική πληροφορία χρησιμοποιήστε τα
            παρακάτω (μαζί με τις αγγύλες):
          </p>
          <br />
          <p><strong>[order.common_id]</strong>: Κωδικός παραγγελίας</p>
          <p><strong>[order.sum_total]</strong>: Ποσό παραγγελίας</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _pick from 'lodash/pick';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';

export default {
  extends: EditItem,
  data() {
    return {
      emailTemplate: {},
      newEmailTemplate: {
        title: '',
        content: '',
      },
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await request.get(`/emailTemplates/${to.params.uuid}`);
      next(vm => vm.setData(data));
    } catch (err) {
      next(vm => vm.$router.push('/error'));
    }
  },
  methods: {
    ...mapActions(['editEmailTemplate', 'deleteEmailTemplate']),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.editEmailTemplate({
          uuid: this.emailTemplate.uuid,
          emailTemplate: this.newEmailTemplate,
        });
        this.isSaving = false;

        this.$router
          .push({ name: 'settings.emailTemplates.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το email αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newEmailTemplate = _pick(this.emailTemplate, ['title', 'content']);

      this.$validator.reset();
    },
    setData(data) {
      this.emailTemplate = data.emailTemplate;
      this.newEmailTemplate = _pick(this.emailTemplate, ['title', 'content']);
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await this.deleteEmailTemplate({
            emailTemplate: this.emailTemplate,
          });

          this.$router
            .push({ name: 'settings.emailTemplates.list' })
            .catch(err => console.log(err));
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'To email διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
