<template>
  <LoadingContainer :is-loading="isLoading || isLoadingTags">
    <form @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Videos <span class="tag">{{ model.youtube_id }}</span>
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link
                  :to="{ name: 'content.videos.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
              <div class="control">
                <button class="button" @click="askToDeleteItem">
                  <span class="icon is-small"><i class="fa fa-trash"/></span
                  ><span>Διαγραφή</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="field">
            <label class="label">Ονομασία *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model.trim="general.title"
                class="input"
                type="text"
                name="title"
              />
            </div>
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε ονομασία
            </p>
          </div>

          <div class="field">
            <label class="label">Περιγραφή *</label>
            <div class="control">
              <textarea
                v-validate="'required'"
                v-model.trim="general.description"
                class="textarea"
                name="description"
              />
            </div>
            <p v-show="errors.has('description')" class="help is-danger">
              Εισάγετε περιγραφή
            </p>
          </div>

          <div class="field">
            <label class="label">YouTube ID *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model.trim="general.youtube_id"
                class="input"
                type="text"
                name="youtube_id"
              />
            </div>
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε youtube id
            </p>
          </div>

          <div class="field">
            <label class="label">Προβολή *</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select v-model.number="general.active" name="active">
                  <option :value="true">Ναί</option>
                  <option :value="false">Όχι</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard :is-open="true" title="Συμπτώματα - Tags">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <label class="label">Συμπτώματα</label>
              <symptoms-search
                :symptoms="general.symptoms"
                @selectSymptom="selectSymptom"
                @removeSymptom="removeSymptom"
              />
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Tags</label>
                <div class="control">
                  <multiselect
                    v-model="general.tags"
                    :options="tags"
                    :multiple="true"
                    name="tags"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε tags"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>

    <confirmation
      :model-name="model.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του Video;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import _pick from 'lodash/pick';
import LoadingContainer from '@/views/components/LoadingContainer';
import ToggleableCard from '@/views/components/ToggleableCard';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';
import SymptomsSearch from '../components/SymptomsSearch';

export default {
  components: {
    Multiselect,
    LoadingContainer,
    ToggleableCard,
    SymptomsSearch,
  },
  extends: EditItem,
  data() {
    return {
      general: {},
    };
  },
  computed: {
    ...mapGetters({
      model: 'videos/edit/getVideo',
      isLoading: 'videos/edit/getIsLoading',
      isLoadingTags: 'tags/all/getIsLoading',
      tags: 'tags/all/getTags',
    }),
  },
  watch: {
    model: {
      handler(newVal) {
        this.general = _pick(newVal, [
          'title',
          'description',
          'youtube_id',
          'active',
          'tags',
          'symptoms',
        ]);
      },
      deep: true,
    },
  },
  async created() {
    try {
      await Promise.all([
        this.getVideo({ uuid: this.$route.params.uuid }),
        this.getTags(),
      ]);
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getVideo: 'videos/edit/getVideo',
      updateVideo: 'videos/edit/updateVideo',
      getTags: 'tags/all/getTags',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateVideo({
          uuid: this.model.uuid,
          video: {
            ...this.general,
            tags: this.general.tags.map(({ id }) => id),
            symptoms: this.general.symptoms.map(({ id }) => id),
          },
        });
        this.isSaving = false;

        this.$router
          .push({ name: 'content.videos.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το video αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = _pick(this.model, [
        'title',
        'description',
        'youtube_id',
        'active',
        'tags',
        'symptoms',
      ]);

      this.$validator.reset();
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/videos/${this.video.uuid}`);
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το video διαγράφτηκε',
          });
          this.$router
            .push({ name: 'content.videos.list' })
            .catch(err => console.log(err));
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    selectSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: [...this.general.symptoms, symptom],
      };
    },
    removeSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: this.general.symptoms.filter(({ id }) => id !== symptom.id),
      };
    },
  },
};
</script>
