<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Υφιστάμενες Κατηγορίες
      </div>
    </div>
    <div v-if="category" class="card-content category-card">
      <h6 class="title is-6">
        {{ category.type | capitalize }} Κατηγορία: {{ category.text }}
      </h6>
      <div class="buttons">
        <span class="button" @click="editItem">
          <span class="icon is-small"><i class="fa fa-pencil"/></span>
          <span>Επεξεργασία</span>
        </span>
        <span v-if="category.type !== 'low'" class="button" @click="addChild">
          <span class="icon is-small"><i class="fa fa-plus"/></span>
          <span>Υποκατηγορία</span>
        </span>
      </div>
    </div>
    <div class="card-content">
      <v-jstree :data="categories" @item-click="itemClick" />
    </div>
  </div>
</template>

<script>
import VJstree from 'vue-jstree';
import _has from 'lodash/has';

export default {
  components: {
    VJstree,
  },
  props: {
    categories: Array,
    categoriesPerType: Object,
  },
  data() {
    return {
      category: null,
    };
  },
  methods: {
    getCategoryType(item) {
      return Object.keys(this.categoriesPerType).find(type =>
        _has(this.categoriesPerType[type], item.id),
      );
    },
    itemClick({ model }) {
      this.category = {
        ...model,
        type: this.getCategoryType(model),
      };
    },
    editItem() {
      this.$router
        .push({
          name: 'products.categories.edit',
          params: {
            uuid: this.category.id,
          },
        })
        .catch(err => console.log(err));
    },
    addChild() {
      this.$router
        .push({
          name: 'products.categories.add',
          query: {
            parent_id: this.category.id,
          },
        })
        .catch(err => console.log(err));
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 0.8rem;
}

.category-card {
  border-bottom: 1px solid #dbdbdb;
}
</style>
