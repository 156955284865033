<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Κατηγορίες Άρθρων <span class="tag">Προσθήκη Κατηγορίας</span>
        </div>
      </div>
      <div class="card-header-icon">
        <router-link
          :to="{ name: 'content.postCategories.list' }"
          class="button"
        >
          <span class="icon is-small"><i class="fa fa-list"/></span>
          <span>Λίστα</span>
        </router-link>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Ονομασία *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="newPostCategory.title"
              class="input"
              type="text"
              name="title"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε ονομασία
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Slug *</label>
          <div class="control">
            <input
              v-validate="'required|regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
              v-model.trim="newPostCategory.slug"
              class="input"
              type="text"
              name="slug"
            />
            <p v-show="errors.has('slug')" class="help is-danger">
              Εισάγετε έγκυρο slug
            </p>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import slug from 'slug';
import request from '@/utils/request';
import AddItem from '@/views/components/AddItem';

export default {
  extends: AddItem,
  data() {
    return {
      newPostCategory: {
        title: '',
        slug: '',
      },
      isSaving: false,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'newPostCategory.title': function(newVal) {
      this.newPostCategory = {
        ...this.newPostCategory,
        slug: slug(newVal.toLowerCase()),
      };
    },
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post(
          '/postCategories',
          this.newPostCategory,
        );
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router
          .push({ name: 'content.postCategories.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η κατηγορία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newPostCategory = {
        title: '',
        slug: '',
      };

      this.$validator.reset();
    },
  },
};
</script>
