<template>
  <LoadingContainer :is-loading="isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Προσθήκη Χρώματος
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.colors.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <label class="label">Ονομασία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newColor.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε ονομασία
                  </p>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column is-one-quarter">
        <ExistingColors :items="items" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingColors from '../../components/ExistingColors';

export default {
  components: {
    EditMetaDetails,
    EditGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingColors,
  },
  extends: AddItem,
  data() {
    return {
      newColor: {
        title: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      items: 'colors/list/getColors',
      isLoadingItems: 'colors/list/getIsLoading',
    }),
  },
  async created() {
    try {
      await this.getColors();
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getColors: 'colors/list/getColors',
      addColor: 'colors/add/addColor',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addColor(this.newColor);
        this.isSaving = false;

        this.$router
          .push({ name: 'products.colors.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το χρώμα αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newColor = {
        title: '',
      };

      this.$validator.reset();
    },
  },
};
</script>
