<template>
  <LoadingContainer :is-loading="isLoadingFilters && !isLoaded">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Ομάδα Φίλτρων
            <router-link
              :to="{
                name: 'products.filterGroups.edit',
                params: { uuid: $route.params.uuid },
              }"
              class="tag"
            >
              {{ filterGroup.title }}
            </router-link>
            Φίλτρα <span v-if="total > 0" class="tag">{{ total }}</span>
          </div>
        </div>
        <div class="card-header-icon">
          <div class="field">
            <div class="control">
              <router-link
                :to="{
                  name: 'products.filterGroups.filters.add',
                  params: { uuid: $route.params.uuid },
                }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-plus"/></span
                ><span>Προσθήκη</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="filters"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="filters.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <sortable-header
                        :sort-options="sortOptions"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="sortOptions"
                        label="Προβολή"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                    <th>Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <filter-item
                    v-for="(item, index) in filters"
                    :filter-group="filterGroup"
                    :item="item"
                    :index="indexStartsFrom + index"
                    :key="item.id"
                    @changeItemActive="changeItemActive"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν φίλτρα
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          :items="filters"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import FilterItem from './components/Filter';

export default {
  components: {
    FilterItem,
  },
  extends: PaginatedList,
  data() {
    return {
      options: [
        {
          label: 'Γενική Αναζήτηση',
          title: 'general',
          type: 'string',
          value: null,
        },
        {
          label: 'Εμφανές',
          title: 'active',
          type: 'boolean',
          value: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filterGroup: 'filterGroups/edit/getFilterGroup',
      filters: 'filters/list/getData',
      paginationParams: 'filters/list/getPaginationParams',
      isLoadingFilters: 'filters/list/getIsLoading',
      isLoaded: 'filters/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;

            await this.getFilters({
              uuid: newVal.params.uuid,
              params: newVal.query,
            });
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                  paginated: 1,
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getFilters: 'filters/list/getFilters',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async changeItemActive(selectedItem) {
      try {
        const isActive = !selectedItem.active;

        await request.put(`/filters/${selectedItem.uuid}/active`, {
          active: !!isActive,
        });

        await this.navigate({
          timestamp: new Date().getTime(),
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Το φίλτρο ενεργοποιήθηκε επιτυχώς!!'
            : 'Το φίλτρο δεν είναι ενεργό πλέον',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
