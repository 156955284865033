<template>
  <CustomersEdit v-bind="$props">
    <div class="card-content">
      <template v-if="customer.guests.length">
        <div class="tabs is-boxed">
          <ul>
            <router-link
              v-slot="{ href, navigate, isExactActive }"
              v-for="guest in customer.guests"
              :to="goTo(guest)"
              :key="guest.id"
              custom
            >
              <li
                :class="[isExactActive && 'router-link-active is-active']"
                role="link"
              >
                <a :href="href" @keypress.enter="navigate" @click="navigate">{{
                  guest.fullName
                }}</a>
              </li>
            </router-link>
          </ul>
        </div>
        <GuestItem
          v-for="guest in customer.guests"
          v-if="guest.uuid === $route.query.guest"
          :guest="guest"
          :key="guest.id"
        />
      </template>
      <h4 v-else class="title is-4 has-text-centered">
        Δε βρέθηκαν guest checkouts
      </h4>
    </div>
  </CustomersEdit>
</template>

<script>
import EditItem from '@/views/components/EditItem';
import CustomersEdit from '../CustomersEdit';
import GuestItem from './components/GuestItem';

export default {
  name: 'CustomersEditGuestCheckouts',
  components: {
    CustomersEdit,
    GuestItem,
  },
  extends: EditItem,
  props: {
    customer: Object,
    tabs: Array,
    activeTab: String,
  },
  created() {
    const { guests } = this.customer;

    if (guests.length && !this.$route.query.guest) {
      this.$router.push(this.goTo(guests[0])).catch(err => console.log(err));
    }
  },
  methods: {
    goTo(guest) {
      return {
        name: this.$route.name,
        params: {
          uuid: this.$route.params.uuid,
        },
        query: {
          tab: this.$route.query.tab,
          guest: guest.uuid,
        },
      };
    },
  },
};
</script>
