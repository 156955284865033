<template>
  <LoadingContainer :is-loading="isLoadingFilterGroups && !isLoaded">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Ομάδα Φίλτρων <span v-if="total > 0" class="tag">{{ total }}</span>
          </div>
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'products.filterGroups.add' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-plus"/></span
                ><span>Προσθήκη</span>
              </router-link>
            </div>
            <div class="control">
              <button class="button" type="button" @click="handleERPSync">
                <span class="icon is-small">
                  <i class="fa fa-cloud-download" />
                </span>
                <span>ERP Sync</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="filterGroups"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="filterGroups.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th>Φίλτρα</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Προβολή"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                    <th>Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <filter-group
                    v-for="(item, index) in filterGroups"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    @changeItemActive="changeItemActive"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν ομάδες φίλτρων
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="filterGroups.length"
          :items="filterGroups"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <SyncModal :is-open="isSyncing" />
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SyncModal from '@/views/components/SyncModal';
import PaginatedList from '@/views/components/PaginatedList';
import FilterGroup from './components/FilterGroup';

export default {
  components: {
    SyncModal,
    FilterGroup,
  },
  extends: PaginatedList,
  data() {
    return {
      isSyncing: false,
      options: [
        {
          label: 'Γενική Αναζήτηση',
          title: 'general',
          type: 'string',
          value: null,
        },
        {
          label: 'Εμφανές',
          title: 'active',
          type: 'boolean',
          value: null,
        },
        {
          label: 'Έχει Φίλτρα',
          title: 'has_filters',
          type: 'boolean',
          value: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filterGroups: 'filterGroups/list/getData',
      paginationParams: 'filterGroups/list/getPaginationParams',
      isLoadingFilterGroups: 'filterGroups/list/getIsLoading',
      isLoaded: 'filterGroups/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getFilterGroups(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                  paginated: 1,
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getFilterGroups: 'filterGroups/list/getFilterGroups',
      updateFilterGroupsActiveStatus:
        'filterGroups/list/updateFilterGroupsActiveStatus',
      checkForFilterGroupsAdditions:
        'filterGroups/list/checkForFilterGroupsAdditions',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async changeItemActive(selectedItem) {
      try {
        const isActive = !selectedItem.active;

        await this.updateFilterGroupsActiveStatus({
          uuid: selectedItem.uuid,
          data: {
            active: !!isActive,
          },
        });

        await this.navigate({
          timestamp: new Date().getTime(),
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Η ομάδα φίλτρων ενεργοποιήθηκε επιτυχώς!!'
            : 'Η ομάδα φίλτρων δεν είναι ενεργή πλέον',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async handleERPSync() {
      try {
        this.isSyncing = true;
        await this.checkForFilterGroupsAdditions();

        await this.navigate({
          page: 1,
          size: 50,
          filters: {},
          sort: {},
          paginated: 1,
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ενημέρωση εκτελέστηκε επιτυχώς!!',
        });
        this.isSyncing = false;
      } catch (err) {
        this.isSyncing = false;

        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
