<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Υφιστάμενες Κατηγορίες
      </div>
    </div>
    <div v-if="category" class="card-content category-card">
      <h6 class="title is-6">Κατηγορία: {{ category.text }}</h6>
      <div class="buttons">
        <span class="button" @click="editItem">
          <span class="icon is-small"><i class="fa fa-pencil"/></span>
          <span>Επεξεργασία</span>
        </span>
      </div>
    </div>
    <div class="card-content">
      <v-jstree :data="categories" @item-click="itemClick" />
    </div>
  </div>
</template>

<script>
import VJstree from 'vue-jstree';

export default {
  components: {
    VJstree,
  },
  props: {
    categories: Array,
    brand: Object,
  },
  data() {
    return {
      category: null,
    };
  },
  methods: {
    itemClick({ model }) {
      this.category = {
        ...model,
      };
    },
    editItem() {
      this.$router
        .push({
          name: 'products.brands.categories.edit',
          params: {
            uuid: this.brand.uuid,
            category: this.category.id,
          },
        })
        .catch(err => console.log(err));
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 0.8rem;
}

.category-card {
  border-bottom: 1px solid #dbdbdb;
}
</style>
