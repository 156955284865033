<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Προσθήκη Παραπόνου
        </div>
      </div>
      <div class="card-header-icon">
        <div class="field has-addons is-marginless">
          <div class="control">
            <router-link :to="{ name: 'complaints.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list-ul"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="columns">
          <div class="column is-full">
            <div class="field">
              <label class="label">Πελάτης *</label>
              <div class="control">
                <customers-search
                  :customer="customer"
                  @selectCustomer="selectCustomer"
                />
                <p v-show="errors.has('customer')" class="help is-danger">
                  Επιλέξτε πελάτη
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-full">
            <div class="field">
              <label class="label">Παράπονο *</label>
              <div class="control">
                <textarea
                  v-validate="'required'"
                  v-model.trim="newComplaint.comment"
                  class="textarea"
                  type="text"
                  name="comment"
                />
                <p v-show="errors.has('comment')" class="help is-danger">
                  Εισάγετε παράπονο
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Ολοκληρώθηκε</label>
              <div class="control">
                <ToggleButton
                  :sync="true"
                  :value="newComplaint.resolved"
                  :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                  :width="30"
                  :height="15"
                  @change="handleChangeResolved"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import FormSubmit from '@/views/components/FormSubmit';
import Notification from '@/views/components/Notification';
import CustomersSearch from '../../../Content/views/Questions/views/components/CustomersSearch';

export default {
  components: {
    Datepicker,
    Notification,
    FormSubmit,
    CustomersSearch,
  },
  data() {
    return {
      newComplaint: {
        comment: '',
        customer_id: '',
        resolved: false,
      },
      customer: null,
      isSaving: false,
    };
  },

  methods: {
    ...mapActions({
      addComplaint: 'complaints/add/addComplaint',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.addComplaint(this.newComplaint);
        this.isSaving = false;

        this.$router
          .push({ name: 'complaints.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το παράπονο αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newComplaint = {
        comment: '',
        customer_id: '',
        resolved: false,
      };
      this.customer = null;
      this.isSaving = false;
      this.$validator.reset();
    },

    selectCustomer(customer) {
      this.customer = customer;
      this.newComplaint = {
        ...this.newComplaint,
        customer_id: this.customer.id,
      };
    },

    handleChangeResolved(e) {
      this.newComplaint = {
        ...this.newComplaint,
        resolved: e.value,
      };
    },
  },
};
</script>
