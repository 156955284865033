<template>
  <div class="print">
    <!-- START for header with orders' ID, DATE, STAUS, CONDITION -->
    <div class="columns is-multiline">
      <div class="column is-full">
        <nav class="level">
          <img src="@/assets/logo_gray.png" alt="logo" class="logo" />
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Skroutz Code</p>
              <p class="title">{{ skroutzSmartCart.code }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Ημερομηνια</p>
              <p class="title">{{ skroutzSmartCart.created_at | timestamp }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Status</p>
              <p class="title">
                <span class="tag is-medium is-primary">
                  {{ skroutzSmartCart.state }}
                </span>
              </p>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <!-- END for header-->

    <div class="print__divider" />

    <div class="columns is-multiline">
      <!-- STARTS for 1st column that contains orders BARCODE and PAYMENT/SEND METHODS -->
      <div class="column">
        <Barcode :value="skroutzSmartCart.code" width="2" height="50">
          Δεν υπάρχει barcode για αυτό το προϊόν
        </Barcode>
      </div>
      <!-- END for the 1st column-->

      <!-- START fro 2nd column that contains orders INVOICE TYPE, ORDERABLE CUSTOMER INFO and INVOICE DETAILS  -->
      <div class="column is-size-4">
        <p><b>Τύπος Παραστατικού:</b> {{ invoiceType.label }}</p>
        <p><b>ΣΤΟΙΧΕΙΑ ΠΕΛΑΤΗ</b></p>
        <p>
          <b>
            {{ skroutzSmartCart.customer.first_name }}
            {{ skroutzSmartCart.customer.last_name }}
          </b>
        </p>
      </div>
      <!-- END for 2nd column-->

      <!-- START for 3rd column that contains orders ADDRESS DETAILS-->
      <div v-if="hasAddress" class="column is-size-4">
        <div class="print__address">
          <p><b>ΣΤΟΙΧΕΙΑ ΠΑΡΑΛΗΠΤΗ</b></p>
          <p>
            {{ skroutzSmartCart.customer.first_name }}
            {{ skroutzSmartCart.customer.last_name }}
          </p>
          <p>
            {{ skroutzSmartCart.customer.address.street_name }}
            {{ skroutzSmartCart.customer.address.street_number }}
            {{ skroutzSmartCart.customer.address.zip }},{{
              skroutzSmartCart.customer.address.city
            }}
          </p>
          <p>
            {{ skroutzSmartCart.customer.address.region }}
          </p>
        </div>
      </div>
      <!-- END for 3rd column-->
    </div>

    <!-- START for orders' PRODUCTS and COSTS -->
    <div class="columns">
      <div class="column">
        <div class="card">
          <div v-if="hasItems">
            <table class="table is-bordered is-striped is-fullwidth">
              <thead>
                <th>#</th>
                <th>Φωτό</th>
                <th>Skroutz ID</th>
                <th>Τελική Τιμή (&euro;)</th>
                <th>Σύνολο (&euro;)</th>
                <th>Ποσότητα</th>
                <th>Stock</th>
              </thead>
              <tbody>
                <ProductSkroutz
                  v-for="(product, index) in skroutzSmartCart.line_items"
                  :key="index"
                  :product="product"
                  :index="index"
                  :about-to-print="true"
                />
                <tr>
                  <td
                    colspan="6"
                    class="has-text-right is-borderless title is-5"
                  >
                    Συνολικό Ποσό
                  </td>
                  <td class="has-text-centered title is-5">
                    <strong>{{ totalPrice }} &euro;</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else class="has-text-centered">
            <i class="fa fa-shopping-basket" />
            <h4 class="title is-4">
              Το καλάθι είναι άδειο
            </h4>
          </div>
        </div>
      </div>
    </div>
    <!-- END for orders' PRODUCTS and COSTS -->

    <!-- START for orders' NOTES -->
    <div class="columns is-multiline">
      <div v-if="hasNotes" class=" column is-4 info">
        <div class="columns is-multiline">
          <div v-if="skroutzSmartCart.comments" class="column is-12">
            <label class="label notification__title">
              Σχόλια για Παραγγελία
            </label>

            <div class="info__inner">
              <div class="card-content">
                {{ skroutzSmartCart.comments }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END for orders' GIFT-WISHES -->
</template>

<script>
import VueBarcode from 'vue-barcode';
import { get, isEmpty } from 'lodash';
import ProductSkroutz from '../../views/EditSkroutz/components/ProductSkroutz';

export default {
  components: {
    Barcode: VueBarcode,
    ProductSkroutz,
  },

  props: {
    skroutzSmartCart: {
      type: Object,
      required: true,
    },
  },

  computed: {
    totalPrice() {
      /* eslint-disable camelcase */
      return Number.parseFloat(
        this.skroutzSmartCart?.line_items.reduce(
          (acc, item) => acc + item.total_price,
          0,
        ),
      ).toFixed(2);
    },

    hasItems() {
      return (
        this.skroutzSmartCart.line_items &&
        this.skroutzSmartCart.line_items.length > 0
      );
    },

    hasAddress() {
      return this.skroutzSmartCart.customer.address;
    },

    hasNotes() {
      return !!this.skroutzSmartCart.comments;
    },

    invoiceType() {
      return this.skroutzSmartCart.invoice
        ? {
            key: 'invoice',
            label: 'Τιμολόγιο',
          }
        : {
            key: 'no_invoice',
            label: 'Απόδειξη',
          };
    },

    isNew() {
      return get(this, 'status.slug', 'new') === 'new';
    },

    customer() {
      return isEmpty(this.skroutzSmartCart.customer);
    },
  },
};
</script>

<style scoped lang="scss">
.fa {
  &.fa-shopping-basket {
    font-size: 40px;
    margin-bottom: 1rem;
  }
}

.type.is-white {
  background-color: white;
  border-color: transparent;
}

.logo {
  width: 100px;
}

::v-deep .badge.is-product {
  background-color: #cacaca;
}

::v-deep a {
  color: #000000;
}

::v-deep .tag {
  background-color: #cacaca !important;
}

::v-deep .info {
  .card-content {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
  }

  &__inner {
    background-color: #ffffff;
    border-radius: 4px;
    &--admin {
      padding-top: 6px;
    }
  }

  .content {
    margin-top: 10px;
  }
}

@media print {
  ::v-deep .vue-barcode-element {
    margin-left: -12px;
    max-width: 150px;
  }

  .print {
    page-break-before: always;
    page-break-after: always;
  }
  // The page breaks stop working if we add /deep/

  ::v-deep .print {
    &__divider {
      border-bottom: 1px solid #dbdbdb;
      margin: 10px 0;
      width: 100%;

      &--notes {
        border-bottom: 1px solid #dbdbdb;
        margin: -10px 0 30px;
        width: 100%;
      }
    }

    &__heading-type {
      margin-top: 9px;
      margin-bottom: -3px;
    }
  }

  ::v-deep .info .card {
    margin-bottom: 0;
  }
}
</style>
