<template>
  <LoadingContainer :is-loading="isLoading">
    <form @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Σελίδες Κειμένου
              <span class="tag">
                <strong>{{ model.title }}</strong>
              </span>
            </div>
          </div>
          <div class="card-header-icon">
            <router-link :to="{ name: 'content.pages.list' }" class="button">
              <span class="icon is-small">
                <i class="fa fa-list" />
              </span>
              <span>Λίστα</span>
            </router-link>
          </div>
        </div>

        <div class="card-content">
          <div class="field">
            <label class="label">Ονομασία *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model.trim="general.title"
                class="input"
                type="text"
                name="title"
              />
              <p v-show="errors.has('title')" class="help is-danger">
                Εισάγετε ονομασία
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Slug *</label>
            <div class="control">
              <input
                v-model.trim="general.slug"
                class="input"
                type="text"
                name="slug"
                disabled
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Περιγραφή *</label>
            <div class="control">
              <textarea
                v-validate="'required'"
                v-model.trim="general.description"
                class="textarea"
                name="description"
              />
              <p v-show="errors.has('description')" class="help is-danger">
                Εισάγετε περιγραφή
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Περιεχόμενο *</label>
            <div class="control">
              <editor
                :content="general.content"
                @changeContent="changeContent"
              />
              <p v-show="errors.has('content')" class="help is-danger">
                Εισάγετε περιεχόμενο
              </p>
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard title="Meta">
        <div class="card-content">
          <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
        </div>
      </ToggleableCard>

      <ToggleableCard title="Social Meta">
        <div class="card-content">
          <EditSocialMetaDetails
            :social-meta="socialMeta"
            @updateSocialMeta="updateSocialMeta"
          />
        </div>
      </ToggleableCard>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </LoadingContainer>
</template>

<script>
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import { mapActions, mapGetters } from 'vuex';
import EditItem from '@/views/components/EditItem';
import LoadingContainer from '@/views/components/LoadingContainer';
import ToggleableCard from '@/views/components/ToggleableCard';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';
import Editor from '@/views/components/Editor';

export default {
  components: {
    LoadingContainer,
    ToggleableCard,
    EditMetaDetails,
    EditSocialMetaDetails,
    EditGeneralDetails,
    Editor,
  },
  extends: EditItem,
  data() {
    return {
      meta: {},
      socialMeta: {},
      general: {},
    };
  },
  computed: {
    ...mapGetters({
      model: 'pages/edit/getPage',
      isLoading: 'pages/edit/getIsLoading',
    }),
  },
  watch: {
    model: {
      handler(newVal) {
        this.general = _pick(newVal, [
          'title',
          'slug',
          'description',
          'content',
        ]);

        this.meta = _clone(this.model.meta);
        this.socialMeta = _clone(this.model.social_meta);
      },
      deep: true,
    },
  },
  async created() {
    try {
      await this.getPage({ uuid: this.$route.params.uuid });
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getPage: 'pages/edit/getPage',
      updatePage: 'pages/edit/updatePage',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updatePage({
          uuid: this.model.uuid,
          page: {
            ...this.general,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η σελίδα αποθηκεύτηκε',
        });
      } catch (err) {
        console.log(err);
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.errors.clear();
      this.general = _pick(this.model, [
        'title',
        'slug',
        'description',
        'content',
      ]);

      this.meta = _clone(this.model.meta);
      this.socialMeta = _clone(this.model.social_meta);
    },
    updateMeta(meta) {
      this.meta = meta;
    },
    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },
    changeContent(content) {
      this.general.content = content;
    },
  },
};
</script>
