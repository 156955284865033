<template>
  <LoadingContainer :is-loading="isLoadingPosts && !isLoadedPosts">
    <div class="card">
      <PaginatedListHeaderTemplate
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        title="Άρθρα"
      >
        <template v-slot:options>
          <router-link :to="{ name: 'content.posts.add' }" class="button">
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </template>
      </PaginatedListHeaderTemplate>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="posts"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoadedPosts"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="posts.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Ημ/νια Δημοσίευσης"
                        attribute="published_at"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Προβολές"
                        attribute="views"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Tags"
                        attribute="tags_count"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Συμπτώματα"
                        attribute="symptoms_count"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Κατηγορίες"
                        attribute="post_categories_count"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Εμφανές"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <post
                    v-for="(item, index) in posts"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @askToDeleteItem="askToDeleteItem"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν άρθρα
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="posts.length"
          :items="posts"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>

      <confirmation
        :model-name="selectedItem.title"
        :is-open="isOpen"
        description="Είστε βέβαιος για τη διαγραφή αυτού του άρθρου;"
        @closeModal="closeModal"
      />
    </div>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import PaginatedListHeaderTemplate from '@/views/components/PaginatedList/components/PaginatedListHeaderTemplate';
import Post from './components/Post';

export default {
  components: {
    Post,
    PaginatedListHeaderTemplate,
  },
  extends: PaginatedList,
  metaInfo: {
    title: 'Άρθρα',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Προβολή',
        title: 'active',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Φωτογραφία Avatar',
        title: 'has_avatar',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει προβολές',
        title: 'views',
        type: 'range',
        value: {
          start: 0,
          end: 0,
        },
      },
      {
        label: 'Έχει Tags',
        title: 'has_tags',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Συμπτώματα',
        title: 'has_symptoms',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Κατηγορίες',
        title: 'has_postCategories',
        type: 'boolean',
        value: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      posts: 'posts/list/getData',
      paginationParams: 'posts/list/getPaginationParams',
      isLoadingPosts: 'posts/list/getIsLoading',
      isLoadedPosts: 'posts/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getPosts(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getPosts: 'posts/list/getPosts',
    }),
    async navigate(params = {}) {
      try {
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/posts/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το άρθρο διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    prepareExportXLS() {
      this.exportXLS(this.selections, ['title', 'active'], 'posts');
    },
    prepareExportCSV() {
      this.exportCSV(this.selections, ['title', 'active'], 'posts');
    },
  },
};
</script>
