<template>
  <LoadingContainer :is-loading="isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Προσθήκη Ομάδας Φίλτρων
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field">
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.filterGroups.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <label class="label">Ονομασία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newFilterGroup.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε ονομασία
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  Ονομασία
                  <span class="icon is-small fa flag-icon flag-icon-gb" />
                </label>
                <div class="control">
                  <input
                    v-model.trim="newFilterGroup.title_en"
                    class="input"
                    type="text"
                    name="title_en"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">
                  Ονομασία e-shop
                  <span class="icon is-small fa fa-shopping-bag" />
                </label>
                <div class="control">
                  <input
                    v-model.trim="newFilterGroup.eshop_title"
                    class="input"
                    type="text"
                    name="eshop_title"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column">
        <ExistingFilterGroups :items="items" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingFilterGroups from '../../components/ExistingFilterGroups';

export default {
  components: {
    EditMetaDetails,
    EditGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingFilterGroups,
  },
  extends: AddItem,
  data() {
    return {
      newFilterGroup: {
        title: '',
        title_en: '',
        eshop_title: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      items: 'filterGroups/list/getData',
      filters: 'filters/all/getFilters',
      isLoadingItems: 'filterGroups/list/getIsLoading',
    }),
  },
  async created() {
    try {
      await this.getFilterGroups();
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getFilterGroups: 'filterGroups/list/getFilterGroups',
      addFilterGroup: 'filterGroups/add/addFilterGroup',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addFilterGroup(this.newFilterGroup);
        this.isSaving = false;

        this.$router
          .push({ name: 'products.filterGroups.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ομάδα φίλτρων αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newFilterGroup = {
        title: '',
        title_en: '',
        eshop_title: '',
      };

      this.$validator.reset();
    },
  },
};
</script>
