<template>
  <LoadingContainer :is-loading="hasLoadedOrders && !isLoadedOrders">
    <div class="card">
      <div
        v-for="selectedOrder in filteredItems"
        :key="selectedOrder.id"
        class="visible-print"
      >
        <order-print :order="selectedOrder" />
      </div>

      <ordersHeader
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        :is-mass-adding-transactions="isMassAddingTransactions"
        :can-send-orders="false"
        title="Απεσταλμένες Παραγγελίες"
        class="hidden-print"
        @navigate="navigate"
        @print="print"
        @prepareExportXLS="prepareExportXLS"
        @prepareExportCSV="prepareExportCSV"
        @massAddTransactions="massAddTransactions"
      />
      <div class="card-content hidden-print">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζητήστε βάσει κωδικού, voucher, ονόματος, email πελάτη"
          @navigate="navigate"
        />

        <NewPagination
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoadedOrders"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="items.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th class="hidden-print">
                      <checkbox
                        v-model="selectedAll"
                        name="selected"
                        @input="selectAll"
                      />
                    </th>
                    <th>Τύπος</th>
                    <th>Κωδικός</th>
                    <th>Ημ/νια</th>
                    <th>Πελάτης</th>
                    <th>Τρόπος Πληρωμής</th>
                    <th>Τρόπος Αποστολής</th>
                    <th>Μέθοδος Παραγγελίας</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τεμάχια"
                        attribute="count"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Αξία (&euro;)"
                        attribute="sum_total"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">Κατάσταση</th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <order
                    v-for="(item, index) in items"
                    :item="item"
                    :index="indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @askToDeleteItem="askToDeleteItem"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν παραγγελίες
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="items.length"
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <confirmation
      :model-name="selectedItem.common_id"
      :is-open="isOpen"
      description="Είστε βέβαιος για την ακύρωση αυτής της παραγγελίας;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import request from '@/utils/request';
import OrdersList from '../../components/OrdersList';

const now = moment().format('YYYY-MM-DD');

export default {
  extends: OrdersList,
  metaInfo: {
    title: 'Απεσταλμένες Παραγγελίες',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Περιέχει προϊόν',
        title: 'product_id',
        type: 'search',
        value: null,
      },
      {
        label: 'Τρόπος Αποστολής',
        title: 'send_method_id',
        type: 'options',
        options: 'sendMethods',
        optionsValue: 'id',
        value: null,
      },
      {
        label: 'Τρόπος Πληρωμής',
        title: 'checkout_method_id',
        type: 'options',
        options: 'checkoutMethods',
        optionsValue: 'id',
        value: null,
      },
      {
        label: 'Μέθοδος Παραγγελίας',
        title: 'order_method_id',
        type: 'options',
        options: 'orderMethods',
        optionsValue: 'id',
        value: null,
      },
      {
        label: 'Είδος Παραστατικού',
        title: 'invoice_type',
        type: 'options',
        options: 'invoice_types',
        optionsValue: 'value',
        value: null,
      },
      {
        label: 'Είναι δώρο',
        title: 'is_gift',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει έξοδα αποστολής',
        title: 'has_send_charge',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει έξοδα πληρωμής',
        title: 'has_checkout_charge',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Εύρος Ημερομηνιών',
        title: 'date',
        type: 'date_range',
        value: {
          start: now,
          end: now,
        },
      },
      {
        label: 'Είναι πληρωμένη',
        title: 'is_paid',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Είναι εκτυπωμένη',
        title: 'is_printed',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει κουπόνι',
        title: 'has_coupons',
        type: 'boolean',
        value: null,
      },
    ],
  }),

  computed: {
    filteredItems() {
      return this.items.filter(({ id }) => this.selections.includes(id));
    },
  },

  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getSentOrders(newVal.query);
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        } finally {
          this.isFiltering = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getSentOrders: 'orders/list/getSentOrders',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isFiltering = false;
      }
    },
    async massAddTransactions() {
      try {
        this.isMassAddingTransactions = true;
        const { data } = await request.put(
          '/orders-transactions',
          {
            selections: this.selections,
          },
          {
            params: {
              page: this.currentPage,
              count: this.itemsPerPage,
              filters: {
                ...this.prepareFilters(this.paginationParams.filters),
                for_delivery: 1,
              },
            },
          },
        );

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'H μαζική προσθήκη πληρωμών εκτελέστηκε',
        });

        this.navigate({
          timestamp: new Date().getTime(),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isMassAddingTransactions = false;
      }
    },
  },
};
</script>
