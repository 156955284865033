<template>
  <div>
    <form @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Tags <span class="tag">Προσθήκη</span>
            </div>
          </div>
          <div class="card-header-icon">
            <router-link :to="{ name: 'content.tags.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
        </div>
        <div class="card-content">
          <AddGeneralDetails
            :general="general"
            @updateGeneral="updateGeneral"
          />

          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Δημοφιλές *</label>
                <div class="control">
                  <ToggleButton
                    :sync="true"
                    :value="general.popular"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="handleChangePopular"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import request from '@/utils/request';
import AddItem from '@/views/components/AddItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import AddGeneralDetails from '@/views/components/AddGeneralDetails';

export default {
  components: {
    ToggleableCard,
    AddGeneralDetails,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        title: '',
        slug: '',
        description: '',
        content: '',
        active: false,
        popular: false,
      },
      isSaving: false,
    };
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/tags', {
          ...this.general,
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router
          .push({ name: 'content.tags.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το tag αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = {
        title: '',
        slug: '',
        description: '',
        content: '',
        active: false,
        popular: false,
      };

      this.$validator.reset();
    },
    updateGeneral(general) {
      this.general = general;
    },

    handleChangePopular(e) {
      this.general = {
        ...this.general,
        popular: e.value,
      };
    },
  },
};
</script>
