<template>
  <LoadingContainer :is-loading="isLoadingPages && !isLoadedPages">
    <div class="card">
      <PaginatedListHeaderTemplate
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        title="Σελίδες Κειμένου"
      >
        <template v-slot:options>
          <router-link :to="{ name: 'content.pages.add' }" class="button">
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </template>
      </PaginatedListHeaderTemplate>
      <div class="card-content">
        <NewPagination
          :items="pages"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoadedPages"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="pages.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <page
                    v-for="(item, index) in pages"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν σελίδες
              </h4>
            </LoadingContainer>
          </div>
        </div>
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaginatedList from '@/views/components/PaginatedList';
import PaginatedListHeaderTemplate from '@/views/components/PaginatedList/components/PaginatedListHeaderTemplate';
import Page from './components/Page';

export default {
  components: {
    Page,
    PaginatedListHeaderTemplate,
  },
  extends: PaginatedList,
  metaInfo: {
    title: 'Σελίδες Κειμένου',
  },
  data() {
    return {
      filtersOptions: {
        title: '',
        content: '',
        active: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      pages: 'pages/list/getData',
      paginationParams: 'pages/list/getPaginationParams',
      isLoadingPages: 'pages/list/getIsLoading',
      isLoadedPages: 'pages/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getPages(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getPages: 'pages/list/getPages',
    }),
    async navigate(params = {}) {
      try {
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    prepareExportXLS() {
      this.exportXLS(this.selections, ['title', 'active'], 'pages');
    },
    prepareExportCSV() {
      this.exportCSV(this.selections, ['title', 'active'], 'pages');
    },
  },
};
</script>
